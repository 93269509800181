


































































import Events from "@/constants/events";
import Vue from "vue";

export default Vue.extend({
  name: "CvoDirectFlightRow",
  props: {
    /**
     * Flight ID that will be sent on the action event payload
     */
    flightId: {
      type: String,
      required: true
    },
    /**
     * Departure time of the flight
     */
    departure: {
      type: String,
      required: true
    },
    /**
     * Departure Airport
     */
    departureStation: {
      type: String,
      required: true
    },
    /**
     * Arrival time of the flight
     */
    arrival: {
      type: String,
      required: true
    },
    /**
     * Arrival Airport
     */
    arrivalStation: {
      type: String,
      required: true
    },
    /**
     * Class for the arrow element
     */
    arrowClass: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Flight duration
     */
    duration: {
      type: String,
      required: true
    },
    /**
     * Number of Stops or Direct. IE: '1 Stop' | 'Direct'
     */
    stops: {
      type: String,
      required: true
    },
    /**
     * Flight Number
     */
    flightCode: {
      type: String,
      required: true
    },
    /**
     * Carrier text IE: 'Operated by Caravelo'
     */
    carrierText: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Flight price
     */
    price: {
      type: [String, Number],
      required: false,
      default: ""
    },
    /**
     * Flight Taxes
     */
    taxes: {
      type: [String, Number],
      required: false,
      default: ""
    },
    /**
     * Currency
     */
    currency: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Action text
     */
    actionText: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Date
     */
    date: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Tag to show instead of the price
     */
    priceTag: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Sum of the price + taxes to show alongside the `priceTag`
     */
    totalPrice: {
      type: [String, Number],
      required: false,
      default: ""
    }
  },
  methods: {
    handleAction() {
      /**
       * Event to select / buy the flight. It provides the `flightId`
       *
       * @event cvo-direct-flight-row-action-click
       */
      this.$emit(Events.CVO_DIRECT_FLIGHT_ROW_ACTION_CLICK, this.flightId);
    }
  }
});
