
































import Vue, { PropType, VueConstructor } from "vue";
import FormElementMixin from "@/mixins/FormElement/FormElement.mixin";
import Events from "@/constants/events";
import { RadioData } from "./model";
import { RadioItem } from "@/models";

const ExtendedVue = Vue as VueConstructor<
  Vue & InstanceType<typeof FormElementMixin>
>;

export default ExtendedVue.extend({
  name: "CvoRadio",

  mixins: [FormElementMixin],

  model: {
    prop: "selectedItem",
    event: Events.CVO_RADIO_CHANGE
  },

  props: {
    /**
     * Sets initial value
     */
    selectedItem: {
      type: Object as PropType<RadioItem>,
      required: false,
      default: null
    },

    /**
     * Items to display in radio button
     */
    items: {
      type: Array as PropType<RadioItem[]>,
      required: true
    }
  },

  data(): RadioData {
    return {
      currentValue: this.selectedItem
    };
  },

  watch: {
    selectedItem(newValue: RadioItem): void {
      this.currentValue = newValue;
    },

    currentValue(): void {
      /**
       * Emits the radio item selected
       *
       * @event cvo-radio-change
       * @property {RadioItem}
       */
      this.$emit(Events.CVO_RADIO_CHANGE, this.currentValue);
    }
  },

  methods: {
    handleChange() {
      /**
       * Emits the radio item selected
       *
       * @event cvo-radio-change
       * @property {RadioItem}
       */
      this.$emit(Events.CVO_RADIO_CHANGE, this.currentValue);
    },
    /**
     * Sets the proper classes
     * @param {object} classes Vee Validate classes https://vee-validate.logaretm.com/v3/guide/state.html#css-classes
     * @returns {object} new object with classes
     */
    setupClasses(classes: object): object {
      const newClasses = {
        ...classes,
        radio: true
      };
      return this.getClasses(newClasses);
    }
  }
});
