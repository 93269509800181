





































import Vue, { PropType, VueConstructor } from "vue";
import Events from "@/constants/events";
import FormElementMixin from "@/mixins/FormElement/FormElement.mixin";
import { SelectItem } from "@/models";
import { ValidationProvider } from "vee-validate";
import { SelectData } from "./model";

const ExtendedVue = Vue as VueConstructor<
  Vue & InstanceType<typeof FormElementMixin>
>;

export default ExtendedVue.extend({
  name: "CvoSelectField",
  components: {
    ValidationProvider
  },

  mixins: [FormElementMixin],

  model: {
    prop: "value",
    event: Events.CVO_SELECT_CHANGE
  },

  props: {
    /**
     * Field model
     */
    value: {
      type: String,
      default: "",
      required: true
    },
    /**
     * Field list of items
     */
    items: {
      type: Array as PropType<SelectItem[]>,
      required: true,
      default: () => []
    },
    /**
     * Field is fullwidth
     */
    isFullwidth: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Field placeholder
     */
    placeholder: {
      type: String,
      default: "",
      required: false
    }
  },

  data(): SelectData {
    return {
      currentValue: this.value
    };
  },

  watch: {
    /**
     * Updates the value when prop changes
     * @param {newValue} string
     */
    value(newValue: string): void {
      this.currentValue = newValue;
    }
  },

  methods: {
    /**
     * Emits an event when changing the select value
     */
    handleChange(): void {
      /**
       * Emits the value of the select field
       *
       * @event cvo-select-change
       * @property {string}
       */
      this.$emit(Events.CVO_SELECT_CHANGE, this.currentValue);
    },

    /**
     * Sets the proper classes
     * @param {object} classes Vee Validate classes https://vee-validate.logaretm.com/v3/guide/state.html#css-classes
     * @returns {object} new object with classes
     */
    setupClasses(classes: object): object {
      const newClasses = {
        ...classes,
        select: true,
        /**
         * Bulma class for full width
         * https://bulma.io/documentation/form/general/
         */
        "is-fullwidth": this.isFullwidth
      };

      return this.getClasses(newClasses);
    }
  }
});
