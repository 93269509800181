





































import Vue, { PropType } from "vue";
import CvoNavigationProfile from "@/components/NavigationProfile/NavigationProfile.vue";
import CvoInformationCard from "@/components/InformationCard/InformationCard.vue";
import { InformationCardInterface, NavigationInterface } from "@/models";
import Events from "@/constants/events";

export default Vue.extend({
  name: "CvoBillingInformationPage",
  components: {
    CvoNavigationProfile,
    CvoInformationCard
  },
  props: {
    /**
     * Page title
     */
    title: {
      type: String,
      required: true
    },
    /**
     * Props for the navigation profile component.
     * Refer to: https://design-system.caravelo.net/cvo-vue-library/storybook/?path=/story/navigationprofile--default-navigation
     */
    navigationProps: {
      type: Object as PropType<NavigationInterface>,
      required: true
    },
    /**
     * Props for the card containing payment info
     * Refer to: https://design-system.caravelo.net/cvo-vue-library/storybook/?path=/story/information-card--default-information-card
     */
    paymentCardProps: {
      type: Object as PropType<InformationCardInterface>,
      required: true
    },
    /**
     * Props for the card containing invoice info
     * Refer to: https://design-system.caravelo.net/cvo-vue-library/storybook/?path=/story/information-card--default-information-card
     */
    invoiceCardProps: {
      type: Object as PropType<InformationCardInterface>,
      required: true
    }
  },
  methods: {
    handleNavigationClick(link: string) {
      /**
       * Emits the navigation event
       *
       * @event cvo-billing-information-page-navigation-click
       * @property {string} link
       */
      this.$emit(Events.CVO_BILLING_INFORMATION_PAGE_NAVIGATION_CLICK, link);
    },

    handleInvoiceClick() {
      /**
       * Event to show the invoices
       *
       * @event cvo-billing-information-page-invoice-click
       */
      this.$emit(Events.CVO_BILLING_INFORMATION_PAGE_INVOICE_CLICK);
    }
  }
});
