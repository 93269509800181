import { VueConstructor } from "vue";
import Vue from "vue";
import FormMixin from "@/mixins/Form/Form.mixin";
import { FormElementValueType } from "@/models";

const ExtendedVue = Vue as VueConstructor<Vue & InstanceType<typeof FormMixin>>;

export default ExtendedVue.extend({
  mixins: [FormMixin],
  props: {
    passwordRules: {
      type: String,
      required: false,
      default: "max:20"
    }
  },
  data() {
    return {
      customFields: {} as { [prop: string]: unknown }
    };
  },
  methods: {
    getPasswordRules(): string {
      return this.customValidations?.password
        ? `required|custom_password|${this.passwordRules}`
        : `required|strong_password|${this.passwordRules}`;
    },

    getPhoneRules(phoneCode: string): string {
      return this.customValidations?.phone
        ? `required|custom_phone:${phoneCode}`
        : "required|numeric|min:5|max:15";
    },

    getPostcodeRules(country: string): string {
      return this.customValidations?.phone
        ? `required|custom_postcode:${country}`
        : "required|alpha_num|min:5|max:15";
    },

    getEmailRules(): string {
      return this.customValidations?.email
        ? `required|custom_email`
        : "required|email|max:100";
    },

    updateCustomModel(modelName: string, value: FormElementValueType): void {
      this.customFields[modelName] = value;
    }
  }
});
