


















import Vue from "vue";
import Events from "@/constants/events";

export default Vue.extend({
  name: "CvoHeroImage",
  props: {
    /**
     * Text to be included in a h1 tag
     */
    title: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Text to be included in a h2 tag
     */
    subtitle: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * A wider piece of text intended to include more detailed explanations
     */
    text: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Text that will appear in the button. If it is not provided, no button will be shown
     */
    buttonText: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * The Url of the image that will be the banner. Required
     */
    imagePath: {
      type: String,
      required: true
    }
  },
  methods: {
    /**
     * @description
     * Emits an event to handle the button click
     */
    buttonClicked(): void {
      /**
       * Event emitted when the button is clicked.
       * @event cvo-hero-image-button-click
       */
      this.$emit(Events.CVO_HERO_IMAGE_BUTTON_CLICK);
    }
  }
});
