



































































































import Vue, { PropType } from "vue";
import Events from "@/constants/events";
import CvoForm from "@/components/Form/Form.vue";
import CvoCheckbox from "@/components/Checkbox/Checkbox.vue";
import CvoRadio from "@/components/Radio/Radio.vue";
import CvoInputField from "@/components/InputField/InputField.vue";
import ActionFeedbackData from "./model";
import { RadioItem } from "@/models";

const OTHER = "other";

export default Vue.extend({
  name: "CvoActionFeedback",
  components: {
    CvoForm,
    CvoCheckbox,
    CvoRadio,
    CvoInputField
  },
  props: {
    /**
     * Component title
     */
    title: {
      type: String,
      required: false,
      default: null
    },

    /**
     * Component description
     */
    description: {
      type: String,
      required: false,
      default: null
    },

    /**
     * Feedback items.
     */
    items: {
      type: Array as PropType<Array<RadioItem>>,
      required: true
    },

    /**
     * Text for the option "other"
     */
    otherText: {
      type: String,
      required: false,
      default: null
    },

    /**
     * Cancel button text
     */
    cancelText: {
      type: String,
      required: false,
      default: null
    },

    /**
     * Submit button text
     */
    submitText: {
      type: String,
      required: false,
      default: null
    },

    /**
     * Extra information
     */
    information: {
      type: String,
      required: false,
      default: null
    },

    /**
     * T&C and Privacy disclaimer
     */
    termsAndConditions: {
      type: String,
      required: false,
      default: null
    },

    /**
     * Whether it accept 1 or more feedback responses
     */
    multiple: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data(): ActionFeedbackData {
    return {
      feedback: null,
      reason: ""
    };
  },

  computed: {
    itemsList(): Array<RadioItem> {
      const items = [...this.items];
      if (!this.multiple && this.otherText) {
        items.push({ id: OTHER, name: this.otherText });
      }
      return items;
    },

    isInputDisabled(): boolean {
      if (this.multiple) {
        return !(this.feedback as Array<RadioItem>)?.find(
          item => item.id === this.otherValue
        );
      } else {
        return (this.feedback as RadioItem)?.id !== this.otherValue;
      }
    },

    otherValue(): string {
      return OTHER;
    }
  },

  created() {
    this.feedback = this.multiple ? [] : {};
  },

  methods: {
    handleCancel(): void {
      /**
       * Event emitted when cancel button is clicked
       * @event action-click
       */
      this.$emit(Events.CVO_ACTION_FEEDBACK_CANCEL);
    },

    handleSubmit(): void {
      const otherReason = this.isInputDisabled ? null : this.reason;
      /**
       * Event emitted when submit button is clicked
       * @event secondario-action-click
       */
      this.$emit(Events.CVO_ACTION_FEEDBACK_SUBMIT, {
        options: this.feedback,
        other: otherReason
      });
    },

    handleMultipleChange(selected: boolean, item: RadioItem) {
      if (selected) {
        (this.feedback as Array<RadioItem>).push(item);
      } else {
        this.feedback = (this.feedback as Array<RadioItem>)?.filter(
          feedback => feedback.id !== item.id
        );
      }
    },

    isFormInvalid(invalid: boolean): boolean {
      let result;

      if (invalid) {
        result = invalid;
      } else {
        if (this.multiple) {
          result = Array.isArray(this.feedback) && this.feedback.length === 0;
        } else {
          result = Object.keys(this.feedback as RadioItem).length === 0;
        }
      }
      return result;
    },

    updateModel(newValue: Array<RadioItem> | RadioItem): void {
      this.feedback = newValue;
    }
  }
});
