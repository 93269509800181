






import Vue from "vue";
import VTooltip from "v-tooltip";

Vue.use(VTooltip, {
  defaultTrigger: "hover focus click",
  defaultTemplate: `
    <div class="CvoTooltip-message" role="tooltip">
      <div class="tooltip-arrow"></div>
      <div class="tooltip-inner"></div>
    </div>`
});

export default Vue.extend({
  name: "CvoTooltip",
  props: {
    msg: {
      required: true,
      type: String,
      default: ""
    }
  }
});
