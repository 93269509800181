































import Vue, { PropType, VueConstructor } from "vue";
import {
  addDays,
  eachDayOfInterval,
  format,
  isAfter,
  isBefore,
  isSameDay,
  subDays,
  getDaysInYear,
  parse
} from "date-fns";
import CvoCarouselSlider from "@/components/CarouselSlider/CarouselSlider.vue";
import { FlightDateSelectorItem } from "@/models";
import Events from "@/constants/events";
import DateMixin from "@/mixins/Date/Date.mixin";
import { EmblaCarouselType } from "embla-carousel";
import { FlightDateSelectorType, DateFormat } from "@/models";

const ExtendedVue = Vue as VueConstructor<Vue & InstanceType<typeof DateMixin>>;

export default ExtendedVue.extend({
  name: "CvoFlightDateSelector",
  components: { CvoCarouselSlider },
  mixins: [DateMixin],
  props: {
    /**
     * The date that will be initially selected and that will work as seed to populate the slider
     */
    initialDate: {
      type: String,
      required: true
    },
    /**
     * The number of dates to be displayed at once
     */
    visibleDates: {
      type: Number,
      required: false,
      default: 7
    },
    /**
     * Wheter the selected date should be always centered on the slide or not
     */
    slideOnSelection: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Whether it's outbound or inbound selector. Used to calculate available dates
     */
    selectorType: {
      type: String as PropType<FlightDateSelectorType>,
      required: false,
      default: null
    }
  },
  data() {
    return {
      /**
       * A date to be used to calculate the items to be displayed on the slider
       */
      seedDate: parse(this.initialDate, DateFormat.ISO, new Date()),
      /**
       * A date to be marked as selected
       */
      selectedDate: parse(this.initialDate, DateFormat.ISO, new Date()),
      /**
       * It indicates what page of the slider to show
       */
      startIndex: 1
    };
  },

  updated() {
    this.setVisibleIndex();
  },

  mounted() {
    this.validateInitialDate();
  },

  watch: {
    initialDate(newValue: string): void {
      this.selectedDate = parse(newValue, DateFormat.ISO, new Date());
      this.seedDate = parse(newValue, DateFormat.ISO, new Date());
      this.validateInitialDate();
    }
  },

  computed: {
    /**
     * Amount of items to be rendered before the selected date
     */
    nDaysBefore(): number {
      let nDaysBefore = Math.floor(this.visibleDates / 2);
      if (
        !this.parsedMinDate ||
        (this.parsedMinDate &&
          !isSameDay(this.selectedDate, this.parsedMinDate))
      ) {
        nDaysBefore = nDaysBefore + this.visibleDates;
      }
      return nDaysBefore;
    },
    /**
     * Amount of items to be rendered after the selected date
     */
    nDaysAfter(): number {
      let nDaysAfter = Math.floor(this.visibleDates / 2);
      nDaysAfter = this.visibleDates % 2 ? nDaysAfter : nDaysAfter - 1;
      if (
        !this.parsedMaxDate ||
        (this.parsedMaxDate &&
          !isSameDay(this.selectedDate, this.parsedMaxDate))
      ) {
        nDaysAfter = nDaysAfter + this.visibleDates;
      }
      return nDaysAfter;
    },
    /**
     * items returns an array of dates to generate 3 pages of the slider
     * the middle one is displayed and the others keep prev/next buttons enabled
     */
    items(): FlightDateSelectorItem[] {
      const items: FlightDateSelectorItem[] = [];

      eachDayOfInterval({
        start: subDays(this.seedDate, this.nDaysBefore),
        end: addDays(this.seedDate, this.nDaysAfter)
      }).map(date => {
        items.push({
          date: date,
          selected: isSameDay(date, this.seedDate),
          disabled: this.isDateDisabled(date),
          text: this.getFormattedText(date)
        });
      });

      return items;
    },

    parsedInitialDate(): Date {
      return parse(this.initialDate, DateFormat.ISO, new Date());
    }
  },
  methods: {
    getFormattedText(date: Date): string {
      return format(date, this.dateFormat);
    },

    setSelectedDate(item: FlightDateSelectorItem): void {
      if (!item.disabled && !item.selected) {
        if (this.slideOnSelection) {
          this.seedDate = item.date;
          this.selectedDate = this.seedDate;
          this.setVisibleIndex();
        } else {
          this.changeSelectedItem(item.date);
        }
        this.selectedDateChanged();
      }
    },

    prevButtonClicked(): void {
      const newDate = subDays(this.selectedDate, this.visibleDates);

      this.selectedDate =
        this.parsedMinDate && isBefore(newDate, this.parsedMinDate)
          ? this.parsedMinDate
          : newDate;

      if (this.isDateDisabled(this.selectedDate)) {
        this.selectedDate = this.getClosestValidDate(this.selectedDate);
      }

      this.setVisibleIndex();

      this.seedDate = this.selectedDate;
      this.selectedDateChanged();
    },

    nextButtonClicked(): void {
      this.startIndex = 1;
      const newDate = addDays(this.selectedDate, this.visibleDates);

      this.selectedDate =
        this.parsedMaxDate && isAfter(newDate, this.parsedMaxDate)
          ? this.parsedMaxDate
          : newDate;

      if (this.isDateDisabled(this.selectedDate)) {
        this.selectedDate = this.getClosestValidDate(this.selectedDate);
      }

      this.seedDate = this.selectedDate;
      this.selectedDateChanged();
    },

    getClosestValidDate(date: Date): Date {
      let validDate = null;
      try {
        let i = 0;
        while (!validDate) {
          i++;
          const prevDate = subDays(date, i);
          const nextDate = addDays(date, i);

          // If no valid dates in a year exit
          if (i > getDaysInYear(date)) {
            throw "There are no available dates.";
          }

          if (!this.isDateDisabled(prevDate)) {
            validDate = prevDate;
          } else if (!this.isDateDisabled(nextDate)) {
            validDate = nextDate;
          }
        }
      } catch (error) {
        validDate = null;
      }

      return validDate || this.seedDate;
    },

    setVisibleIndex(): void {
      if (this.nDaysBefore < this.visibleDates) {
        this.startIndex = 0;
      } else {
        this.startIndex = 1;
      }
      // HACK: when setting the index and minDate == selectedDate it won't scroll to 0;
      const cvoCarousel = this.$refs.carousel as Vue & {
        carousel: EmblaCarouselType;
      };
      cvoCarousel.carousel.scrollTo(this.startIndex, true);
    },

    addSlideClasses(item: FlightDateSelectorItem): string {
      let classes = `is-${this.visibleDates}`;
      classes += item.selected ? " CvoFlightDateSelector-selectedDate" : "";
      classes += item.disabled ? " CvoFlightDateSelector-disabled" : "";

      return classes;
    },

    changeSelectedItem(selectedDate: Date): void {
      this.deselectCurrentDate();

      const newSelectedDateIndex = this.items.findIndex(item => {
        return item.date == selectedDate;
      });

      this.items[newSelectedDateIndex].selected = true;
      this.selectedDate = selectedDate;
    },

    deselectCurrentDate(): void {
      const selectedDateIndex = this.items.findIndex(item => {
        return item.selected === true;
      });

      this.items[selectedDateIndex].selected = false;
    },

    validateInitialDate(): void {
      if (this.isDateDisabled(this.parsedInitialDate)) {
        this.moveInitialDate();
      }
      this.setVisibleIndex();
    },

    moveInitialDate(): void {
      const moveFn =
        this.selectorType === FlightDateSelectorType.OUTBOUND
          ? subDays
          : addDays;

      const isBeyondLimit =
        this.selectorType === FlightDateSelectorType.OUTBOUND
          ? this.isBeforeMinDate
          : this.isAfterMaxDate;

      let validDate = null;
      let currentDate = this.parsedInitialDate;
      while (!validDate || isBeyondLimit(currentDate)) {
        currentDate = moveFn(currentDate, 1);
        validDate = this.isDateDisabled(currentDate) ? null : currentDate;
      }

      if (validDate) {
        this.seedDate = validDate;
        this.selectedDate = validDate;
      } else {
        this.handleInvalidInitialDate();
      }
    },

    selectedDateChanged(): void {
      /**
       * Emits an event when a date has been selected
       *
       * @event cvo-flight-date-selector-date-select
       */
      this.emitDateEvent(
        Events.CVO_FLIGHT_DATE_SELECTOR_DATE_SELECT,
        this.selectedDate
      );
    },

    handleInvalidInitialDate(): void {
      /**
       * Emits an event when a date has been selected
       *
       * @event cvo-flight-date-selector-invalid-initial
       */
      this.$emit(Events.CVO_FLIGHT_DATE_SELECTOR_INVALID_INITIAL);
    }
  }
});
