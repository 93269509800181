








import Vue from "vue";

export default Vue.extend({
  name: "CvoLandingPage",
  methods: {
    emitEvent(): void {
      this.$emit("cvo-dummy-next-event");
    }
  }
});
