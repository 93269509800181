


























































































import Vue from "vue";
import CvoTooltip from "@/components/Tooltip/Tooltip.vue";

export default Vue.extend({
  name: "CvoCardAdyen",
  components: { CvoTooltip },
  props: {
    /**
     * Label for the credit card input
     */
    creditCardLabel: {
      type: String,
      required: true
    },
    /**
     * Label for the expiration date input
     */
    expiryDateLabel: {
      type: String,
      required: true
    },
    /**
     * Label for the cvv code
     */
    cvvLabel: {
      type: String,
      required: true
    },
    /**
     * Content for the cvv tooltip message
     */
    tooltipMsg: {
      type: String,
      required: true
    },
    /**
     * Label for the card holder
     */
    holderLabel: {
      type: String,
      required: true
    },
    /**
     * Placeholder for the card holder
     */
    holderPlaceholder: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      holderName: ""
    };
  }
});
