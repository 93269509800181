








import Vue from "vue";
import Events from "@/constants/events";

export default Vue.extend({
  name: "CvoClose",
  methods: {
    handleClick() {
      /**
       * Close event
       * @event cvo-close-click
       */
      this.$emit(Events.CVO_CLOSE_CLICK);
    }
  }
});
