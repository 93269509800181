import "./LoadingV2.scss";
import CvoLoader from "@/components/Loader/Loader.vue";
import { DirectiveBinding } from "vue/types/options";

export default {
  inserted(el: HTMLElement) {
    const loader = new CvoLoader().$mount();
    const container = document.createElement("div");
    container.className = "CvoLoaderContainer";
    container.appendChild(loader.$el);
    el.appendChild(container);
  },
  update(el: HTMLElement, binding: DirectiveBinding) {
    if (binding.value) {
      el.classList.add("is-loading");
      el.setAttribute("disabled", "disabled");
    } else {
      el.classList.remove("is-loading");
      el.removeAttribute("disabled");
    }
  }
};
