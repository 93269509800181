














































































import Vue, { PropType } from "vue";
import CvoNavigationProfile from "@/components/NavigationProfile/NavigationProfile.vue";
import CvoInformationCard from "@/components/InformationCard/InformationCard.vue";
import CvoDefinitionList from "@/components/DefinitionList/DefinitionList.vue";
import {
  DefinitionListInterface,
  InformationCardInterface,
  NavigationInterface
} from "@/models";
import Events from "@/constants/events";

export default Vue.extend({
  name: "CvoProfileOverviewPage",
  components: {
    CvoNavigationProfile,
    CvoInformationCard,
    CvoDefinitionList
  },
  props: {
    /**
     * Page title
     */
    title: {
      type: String,
      required: true
    },
    /**
     * Props for the navigation profile component.
     * Refer to: https://design-system.caravelo.net/cvo-vue-library/storybook/?path=/story/navigationprofile--default-navigation
     */
    navigationProps: {
      type: Object as PropType<NavigationInterface>,
      required: true
    },
    /**
     * Props for the card containing plan info
     * Refer to: https://design-system.caravelo.net/cvo-vue-library/storybook/?path=/story/information-card--default-information-card
     */
    planCardProps: {
      type: Object as PropType<InformationCardInterface>,
      required: true
    },
    /**
     * Props for the card containing payment info
     * Refer to: https://design-system.caravelo.net/cvo-vue-library/storybook/?path=/story/information-card--default-information-card
     */
    paymentCardProps: {
      type: Object as PropType<InformationCardInterface>,
      required: true
    },
    /**
     * Props for the card containing beneficiary info
     * Refer to: https://design-system.caravelo.net/cvo-vue-library/storybook/?path=/story/information-card--default-information-card
     */
    beneficiaryCardProps: {
      type: Object as PropType<InformationCardInterface>,
      required: true
    },
    /**
     * Props for the credit card summary
     * Refer to: https://design-system.caravelo.net/cvo-vue-library/storybook/?path=/story/definition-list--default-definition-list
     */
    creditCardSummaryProps: {
      type: Object as PropType<DefinitionListInterface>,
      required: true
    }
  },
  data() {
    return {
      events: Events
    };
  },
  methods: {
    handleNavigationClick(link: string) {
      /**
       * Emits the navigation event
       *
       * @event cvo-profile-overview-page-navigation-click
       * @property {string} link
       */
      this.$emit(Events.CVO_PROFILE_OVERVIEW_PAGE_NAVIGATION_CLICK, link);
    },

    emitEvent(eventName: string) {
      /**
       * Emits the event name provided
       *
       * @event cvo-profile-overview-*
       * @property {string} eventName
       */
      this.$emit(eventName);
    }
  }
});
