enum Events {
  CVO_ACTION_FEEDBACK_CANCEL = "cvo-action-feedback-cancel",
  CVO_ACTION_FEEDBACK_SUBMIT = "cvo-action-feedback-submit",
  CVO_ADDON_ACTION = "cvo-addon-action",
  CVO_AIRPORT_SERVICES_TARIFFS_AIRPORTS = "cvo-airport-services-tariffs-airports",
  CVO_AIRPORT_SERVICES_TARIFFS_AIRPORT_SELECTED = "cvo-airport-services-tariffs-airport-selected",
  CVO_AUTOCOMPLETE_CHANGE = "cvo-autocomplete-change",
  CVO_AUTOCOMPLETE_SEARCH = "cvo-autocomplete-search",
  CVO_BILLING_INFORMATION_PAGE_INVOICE_CLICK = "cvo-billing-information-page-invoice-click",
  CVO_BILLING_INFORMATION_PAGE_NAVIGATION_CLICK = "cvo-billing-information-page-navigation-click",
  CVO_CAROUSEL_SLIDER_SLIDE_SELECT = "cvo-carousel-slider-slide-select",
  CVO_CAROUSEL_SLIDER_PREV_BUTTON_CLICK = "cvo-carousel-slider-prev-button-click",
  CVO_CAROUSEL_SLIDER_NEXT_BUTTON_CLICK = "cvo-carousel-slider-next-button-click",
  CVO_CART_APPLY_COUPON = "cvo-cart-apply-coupon",
  CVO_CART_CONTINUE = "cvo-cart-continue",
  CVO_CART_EDIT_CART = "cvo-cart-edit-cart",
  CVO_CART_EDIT_PASSENGER = "cvo-cart-edit-passenger",
  CVO_CHECKBOX_CHANGE = "cvo-checkbox-change",
  CVO_CLOSE_CLICK = "cvo-close-click",
  CVO_CONFIGURE_PLAN_PAGE_CYCLE_CHANGE = "cvo-configure-plan-page-cycle-change",
  CVO_CONFIGURE_PLAN_PAGE_PLAN_CHANGE = "cvo-configure-plan-page-plan-change",
  CVO_CONFIGURE_PLAN_PAGE_SUBMIT = "cvo-configure-plan-page-submit",
  CVO_CONFIRMATION_PAGE_PURCHASE_CONFIRMATION_BUTTON_CLICK = "cvo-confirmation-page-purchase-confirmation-button-click",
  CVO_CONFIRMATION_PAGE_BENEFICIARY_ACTION = "cvo-confirmation-page-beneficiary-action",
  CVO_CONFIRMATION_PAGE_BENEFICIARY_SECONDARY_ACTION = "cvo-confirmation-page-beneficiary-secondary-action",
  CVO_CONFIRMATION_PAGE_PAYMENT_ACTION = "cvo-confirmation-page-payment-action",
  CVO_CONFIRMATION_PAGE_PAYMENT_SECONDARY_ACTION = "cvo-confirmation-page-payment-secondary-action",
  CVO_CONFIRMATION_PAGE_PLAN_ACTION = "cvo-confirmation-page-plan-action",
  CVO_CONFIRMATION_PAGE_PLAN_SECONDARY_ACTION = "cvo-confirmation-page-plan-secondary-action",
  CVO_DATEPICKER_CALENDAR_CHANGE = "cvo-datepicker-calendar-change",
  CVO_DATEPICKER_CHANGE = "cvo-datepicker-change",
  CVO_DATEPICKER_OPEN = "cvo-datepicker-open",
  CVO_DIRECT_FLIGHT_ROW_ACTION_CLICK = "cvo-direct-flight-row-action-click",
  CVO_EDIT_CARD_ADYEN_EDIT_MODE = "cvo-edit-card-adyen-edit-mode",
  CVO_EDIT_CARD_ADYEN_SAVE = "cvo-edit-card-adyen-save",
  CVO_FAQ_MORE_INFO = "cvo-more-info",
  CVO_FLIGHT_DATE_SELECTOR_DATE_SELECT = "cvo-flight-date-selector-date-select",
  CVO_FLIGHT_DATE_SELECTOR_INVALID_INITIAL = "cvo-flight-date-selector-invalid-initial",
  CVO_FOOTER_LINK_CLICK = "cvo-footer-link-click",
  CVO_FORM_SUBMIT = "cvo-form-submit",
  CVO_HEADER_FORGOT_CLICK = "cvo-header-forgot-click",
  CVO_HEADER_LANGUAGE_APPLY = "cvo-header-language-apply",
  CVO_HEADER_LINK_CLICK = "cvo-header-link-click",
  CVO_HEADER_LOGIN_BUTTON_CLICK = "cvo-header-login-button-click",
  CVO_HEADER_LOGIN_SUBMIT = "cvo-header-login-submit",
  CVO_HEADER_LOGOUT = "cvo-header-logout",
  CVO_HEADER_USER_AREA = "cvo-header-user-area",
  CVO_HEADER_USER_BUTTON_CLICK = "cvo-header-user-button-click",
  CVO_HERO_IMAGE_BUTTON_CLICK = "cvo-hero-image-button-click",
  CVO_INFORMATION_CARD_ACTION_CLICK = "cvo-information-card-action-click",
  CVO_INFORMATION_CARD_SECONDARY_ACTION_CLICK = "cvo-information-card-secondary-action-click",
  CVO_LANGUAGE_SELECTOR_APPLY = "cvo-language-selector-apply",
  CVO_LOGIN_FORGOT_CLICK = "cvo-login-forgot-click",
  CVO_LOGIN_SUBMIT = "cvo-login-submit",
  CVO_MODAL_CLOSE = "cvo-modal-close",
  CVO_MODAL_OPEN = "cvo-modal-open",
  CVO_MULTI_OPTION_PLAN_EXTRA_INFO = "cvo-multi-option-plan-extra-info",
  CVO_MULTI_OPTION_PLAN_SUBMIT_BUTTON = "cvo-multi-option-plan-submit-button",
  CVO_NAVIGATION_CLICK = "cvo-navigation-click",
  CVO_NOTIFICATION_CLOSE = "cvo-notification-close",
  CVO_OVERLAY_OUTSIDE_CLICK = "cvo-overlay-outside-click",
  CVO_PAYMENT_CITY_CHANGE = "cvo-payment-city-change",
  CVO_PAYMENT_PAGE_APPLY_COUPON = "cvo-payment-page-apply-coupon",
  CVO_PAYMENT_PAGE_EDIT_CART = "cvo-payment-page-edit-cart",
  CVO_PAYMENT_PAGE_CITY_CHANGE = "cvo-payment-page-city-change",
  CVO_PAYMENT_PAGE_COUNTRY_CHANGE = "cvo-payment-page-country-change",
  CVO_PAYMENT_PAGE_PROVINCE_STATE_CHANGE = "cvo-payment-page-province-state-change",
  CVO_PAYMENT_PAGE_SUBMIT = "cvo-payment-page-submit",
  CVO_PAYMENT_PROVINCE_STATE_CHANGE = "cvo-payment-province-state-change",
  CVO_PAYMENT_COUNTRY_CHANGE = "cvo-payment-country-change",
  CVO_PAYMENT_SUBMIT = "cvo-payment-submit",
  CVO_PLAN_ACTION = "cvo-plan-action",
  CVO_PLAN_COMPARISON_CANCEL = "cvo-plan-comparison-cancel",
  CVO_PLAN_COMPARISON_SUBMIT = "cvo-plan-comparison-submit",
  CVO_PLAN_DETAILS_BUTTON_CLICK = "cvo-plan-details-button-click",
  CVO_PROFILE_OVERVIEW_PAGE_BENEFICIARY_ACTION = "cvo-profile-overview-page-beneficiary-action",
  CVO_PROFILE_OVERVIEW_PAGE_BENEFICIARY_SECONDARY_ACTION = "cvo-profile-overview-page-beneficiary-secondary-action",
  CVO_PROFILE_OVERVIEW_PAGE_NAVIGATION_CLICK = "cvo-profile-overview-page-navigation-click",
  CVO_PROFILE_OVERVIEW_PAGE_PAYMENT_ACTION = "cvo-profile-overview-page-payment-action",
  CVO_PROFILE_OVERVIEW_PAGE_PAYMENT_SECONDARY_ACTION = "cvo-profile-overview-page-payment-secondary-action",
  CVO_PROFILE_OVERVIEW_PAGE_PLAN_ACTION = "cvo-profile-overview-page-plan-action",
  CVO_PROFILE_OVERVIEW_PAGE_PLAN_SECONDARY_ACTION = "cvo-profile-overview-page-plan-secondary-action",
  CVO_PURCHASE_CONFIRMATION_BUTTON_CLICK = "cvo-purchase-confirmation-button-click",
  CVO_RADIO_CHANGE = "cvo-radio-change",
  CVO_REGISTER_COUNTRY_CHANGE = "cvo-register-country-change",
  CVO_REGISTER_CITY_CHANGE = "cvo-register-city-change",
  CVO_REGISTER_PROVINCE_STATE_CHANGE = "cvo-register-province-state-change",
  CVO_REGISTER_SUBMIT = "cvo-register-submit",
  CVO_REGISTRATION_PAGE_APPLY_COUPON = "cvo-registration-page-apply-coupon",
  CVO_REGISTRATION_PAGE_EDIT_CART = "cvo-registration-page-edit-cart",
  CVO_REGISTRATION_PAGE_CITY_CHANGE = "cvo-registration-page-city-change",
  CVO_REGISTRATION_PAGE_COUNTRY_CHANGE = "cvo-registration-page-country-change",
  CVO_REGISTRATION_PAGE_PROVINCE_STATE_CHANGE = "cvo-registration-page-province-state-change",
  CVO_REGISTRATION_PAGE_SUBMIT = "cvo-registration-page-submit",
  CVO_SEARCH_FLIGHT_DESTINATION = "cvo-search-flight-destination",
  CVO_SEARCH_FLIGHT_ORIGIN = "cvo-search-flight-origin",
  CVO_SEARCH_FLIGHT_SUBMIT = "cvo-search-flight-submit",
  CVO_SEARCH_RESULT_DATE_SELECT = "cvo-search-result-date-select",
  CVO_SELECT_CHANGE = "cvo-select-change",
  CVO_TOGGLE_BUTTON_CHANGE = "cvo-toggle-button-change",
  CVO_VERTICAL_PLAN_EXTRA_INFO = "cvo-vertical-plan-extra-info",
  CVO_VERTICAL_PLAN_SUBMIT_BUTTON = "cvo-vertical-plan-submit-button"
}

export default Events;
