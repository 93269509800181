










































































































































































































import Vue, { VueConstructor } from "vue";
import CartMixin from "@/mixins/Cart/Cart.mixin";

const ExtendedVue = Vue as VueConstructor<Vue & InstanceType<typeof CartMixin>>;

export default ExtendedVue.extend({
  name: "CvoSimpleCart",
  mixins: [CartMixin]
});
