








import Vue, { PropType } from "vue";
import CvoAccordion from "@/components/Accordion/Accordion.vue";
import { AccordionItem } from "@/models";
import Events from "@/constants/events";

export default Vue.extend({
  name: "CvoFaq",
  components: { CvoAccordion },
  props: {
    /**
     * Optional text to be added on top of the accordion of questions/answers.
     */
    title: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Array of AccordionItems used for the questions and its answers.
     */
    items: {
      type: Array as PropType<AccordionItem[]>,
      required: true
    },
    /**
     * Text for an optional button under the FAQ. Intended for providing more info but it only emits and event.
     * If no text is provided no button will be displayed.
     */
    buttonText: {
      type: String,
      required: false,
      default: ""
    }
  },
  methods: {
    buttonClicked(): void {
      /**
       * Emits an event when the button is clicked
       *
       * @event cvo-checkbox-change
       */
      this.$emit(Events.CVO_FAQ_MORE_INFO);
    }
  }
});
