/* eslint-disable */

/**
 * TODO: move all the l18n data to external service
 */

const locale = {
  es: {
    code: "es",
    messages: {
      alpha: "Este campo solo debe contener letras",
      alpha_dash: "Este campo solo debe contener letras, números y guiones",
      alpha_num: "Este campo solo debe contener letras y números",
      alpha_spaces: "Este campo solo debe contener letras y espacios",
      between: "Este campo debe estar entre {min} y {max}",
      confirmed: "Este campo no coincide",
      credit_card: "El numero de tarjeta no es válido",
      digits:
        "Este campo debe ser numérico y contener exactamente {length} dígitos",
      dimensions: "Este campo debe ser de {width} píxeles por {height} píxeles",
      email: "Este campo debe ser un correo electrónico válido",
      excluded: "Este campo debe ser un valor válido",
      ext: "Este campo debe ser un archivo válido",
      image: "Este campo debe ser una imagen",
      oneOf: "Este campo debe ser un valor válido",
      integer: "Este campo debe ser un entero",
      length: "El largo de este campo debe ser {length}",
      max: "Este campo no debe ser mayor a {length} caracteres",
      max_value: "Este campo debe de ser {max} o menor",
      mimes: "Este campo debe ser un tipo de archivo válido",
      min: "Este campo debe tener al menos {length} caracteres",
      min_value: "Este campo debe ser {min} o superior",
      numeric: "Este campo debe contener solo caracteres numéricos",
      regex: "El formato de este campo no es válido",
      required: "Este campo es obligatorio",
      required_if: "Este campo es obligatorio",
      size: "Este campo debe ser menor a {size}KB",
      double: "Este campo debe ser un decimal válido",
      strong_password: "La contraseña debe ser de al menos 8 caracteres, y debe contener al menos: 1 letra mayúscula, 1 letra minúscula, 1 número, y 1 caracter especial",
      regular_password: "La contraseña debe ser de al menos 8 caracteres, y debe contener al menos: 1 letra mayúscula, 1 letra minúscula y 1 número",
      alpha_num_space: "Este campo solo debe contener letras, números y espacios",
      not_under_18: "El titular de la membresía debe tener 18 años o más"
    }
  },
  en: {
    code: "en",
    messages: {
      alpha: "The field may only contain alphabetic characters",
      alpha_num: "The field may only contain alpha-numeric characters",
      alpha_dash:
        "The field may contain alpha-numeric characters as well as dashes and underscores",
      alpha_spaces:
        "The field may only contain alphabetic characters as well as spaces",
      between: "The field must be between {min} and {max}",
      confirmed: "The field confirmation does not match",
      credit_card: "The credit card number is not valid",
      digits: "The field must be numeric and exactly contain {length} digits",
      dimensions: "The field must be {width} pixels by {height} pixels",
      email: "The field must be a valid email",
      excluded: "The field is not a valid value",
      ext: "The field is not a valid file",
      image: "The field must be an image",
      integer: "The field must be an integer",
      length: "The field must be {length} long",
      max_value: "The field must be {max} or less",
      max: "The field may not be greater than {length} characters",
      mimes: "The field must have a valid file type",
      min_value: "The field must be {min} or more",
      min: "The field must be at least {length} characters",
      numeric: "The field may only contain numeric characters",
      oneOf: "The field is not a valid value",
      regex: "The field format is invalid",
      required_if: "The field is required",
      required: "The field is required",
      size: "The field size must be less than {size}KB",
      double: "The field must be a valid decimal",
      strong_password: "The password must be at least 8 characters long, and must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character",
      regular_password: "The password must be at least 8 characters long, and must contain at least: 1 uppercase letter, 1 lowercase letter and 1 number",
      alpha_num_space: "The field may only contain alpha-numeric and space characters",
      not_under_18: "The membership holder must be 18 years or older"
    }
  }
}

export default locale;
