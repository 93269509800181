
























































import Vue, { PropType } from "vue";
import CvoSelectField from "@/components/SelectField/SelectField.vue";
import CvoOverlay from "@/components/Overlay/Overlay.vue";
import { SelectItem } from "@/models";
import Events from "@/constants/events";

export default Vue.extend({
  name: "CvoLanguageSelector",
  components: {
    CvoSelectField,
    CvoOverlay
  },
  props: {
    /**
     * Current languge to show on the selector button
     */
    currentLanguage: {
      type: Object as PropType<SelectItem>,
      required: true
    },
    /**
     * Current currency to show on the selector button
     */
    currentCurrency: {
      type: Object as PropType<SelectItem>,
      required: true
    },
    /**
     * Title for the panel
     */
    panelTitle: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Label for the language select
     */
    languageLabel: {
      type: String,
      required: true
    },
    /**
     * List of languages
     */
    languageList: {
      type: Array as PropType<SelectItem[]>,
      required: true
    },
    /**
     * Label for the currency select
     */
    currencyLabel: {
      type: String,
      required: true
    },
    /**
     * List of currencies
     */
    currencyList: {
      type: Array as PropType<SelectItem[]>,
      required: true
    },
    /**
     * Text for the confirm button
     */
    confirmText: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      language: this.currentLanguage.id,
      currency: this.currentCurrency.id,
      isPanelVisible: false
    };
  },
  methods: {
    handleApplyChanges(): void {
      const payload = { language: this.language, currency: this.currency };
      /**
       * Emits an event in order to apply the new language
       * and currency selcted `LanguageSelectorPayload`
       *
       * @event cvo-language-selector-apply
       */
      this.$emit(Events.CVO_LANGUAGE_SELECTOR_APPLY, payload);

      this.closePanel();
    },

    togglePanel(): void {
      this.isPanelVisible = !this.isPanelVisible;
    },

    closePanel(): void {
      this.isPanelVisible = false;
    }
  }
});
