





































import Vue, { VueConstructor } from "vue";
import FormElementMixin from "@/mixins/FormElement/FormElement.mixin";
import CvoTooltip from "@/components/Tooltip/Tooltip.vue";

const ExtendedVue = Vue as VueConstructor<
  Vue & InstanceType<typeof FormElementMixin>
>;

export default ExtendedVue.extend({
  name: "CvoInputField",

  components: { CvoTooltip },

  mixins: [FormElementMixin],

  model: {
    prop: "value",
    event: "input"
  },

  props: {
    /**
     * Field model
     */
    value: {
      type: String,
      default: "",
      required: true
    },
    /**
     * Input type
     */
    type: {
      type: String,
      default: "text",
      required: true,
      validator: function(value: string): boolean {
        return (
          ["text", "email", "password", "tel", "number", "hidden"].indexOf(
            value
          ) !== -1
        );
      }
    },
    /**
     * Field placeholder
     */
    placeholder: {
      type: String,
      default: "",
      required: false
    },
    /**
     * Tooltip message
     */
    tooltipMsg: {
      type: String,
      required: false,
      default: ""
    }
  },

  data() {
    return {
      currentValue: this.value
    };
  },

  watch: {
    /**
     * Updates the value when prop changes
     * @param {newValue} string
     */
    value(newValue: string): void {
      this.currentValue = newValue;
    }
  },

  methods: {
    /**
     * Emits the input change
     */
    handleChange() {
      /**
       * Event emitted on input change
       *
       * @event input
       * @property {string}
       */
      this.$emit("input", this.currentValue);
    },

    /**
     * Sets the proper classes
     * @param {object} classes Vee Validate classes https://vee-validate.logaretm.com/v3/guide/state.html#css-classes
     * @returns {object} new object with classes
     */
    setupClasses(classes: object): object {
      const newClasses = {
        ...classes,
        input: true
      };
      return this.getClasses(newClasses);
    }
  }
});
