























import Vue from "vue";
import VueModal from "vue-js-modal";
import Events from "@/constants/events";
import CvoClose from "@/components/Close/Close.vue";
import ModalData from "./model";

Vue.use(VueModal);

/**
 * Modal based on https://github.com/euvl/vue-js-modal
 */
export default Vue.extend({
  name: "CvoModal",
  components: {
    CvoClose
  },
  props: {
    /** Display the modal */
    display: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data(): ModalData {
    return {
      modalName: ""
    };
  },
  watch: {
    display: function(newValue: boolean) {
      newValue ? this.handleShow() : this.handleHide();
    }
  },
  beforeMount() {
    this.modalName = this.getModalId();
  },
  mounted() {
    this.display ? this.handleShow() : this.handleHide();
  },
  methods: {
    handleShow() {
      this.$modal.show(this.modalName);
    },
    handleHide() {
      this.$modal.hide(this.modalName);
    },
    handleOpened() {
      /**
       * Emits after modal became visible or started transition.
       *
       * @event cvo-modal-open
       */
      this.$emit(Events.CVO_MODAL_OPEN);
    },
    handleClosed() {
      /**
       * Emits right before modal is destroyed
       *
       * @event cvo-modal-close
       */
      this.$emit(Events.CVO_MODAL_CLOSE);
    },
    getModalId(): string {
      function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      }

      return (
        S4() +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        S4() +
        S4()
      );
    }
  }
});
