











































































import Vue, { PropType } from "vue";
import CvoForm from "@/components/Form/Form.vue";
import CvoRadio from "@/components/Radio/Radio.vue";
import CvoAutocomplete from "@/components/Autocomplete/Autocomplete.vue";
import CvoDatePicker from "@/components/Datepicker/Datepicker.vue";
import Events from "@/constants/events";
import SearchFlightData from "./model";
import { AutocompleteItem, CityQuery, TripType } from "@/models";
import { DateFormat } from "@/models";
import {
  isBefore,
  isAfter,
  isSameDay,
  isWithinInterval,
  format,
  parse
} from "date-fns";

enum Classes {
  IN_RANGE = "in-range",
  SELECTED_RANGE = "selected-range",
  IS_DEPARTURE = "is-departure",
  IS_RETURN = "is-return"
}

const CELLS_SELECTOR =
  ".CvoDatepicker-datepicker-popup .cell:not(.disabled):not(.is-departure):not(.is-return)";

export default Vue.extend({
  name: "CvoSearchFlight",

  components: {
    CvoForm,
    CvoRadio,
    CvoAutocomplete,
    CvoDatePicker
  },

  props: {
    /**
     * Label for the origin city
     */
    originLabel: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Placeholder for the origin city
     */
    originPlaceholder: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Label for the destination city
     */
    destinationLabel: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Placeholder for the destination city
     */
    destinationPlaceholder: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Label for the departure date
     */
    departureLabel: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Placeholder for the departure date
     */
    departurePlaceholder: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Label for the return date
     */
    returnLabel: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Placeholder for the return date
     */
    returnPlaceholder: {
      type: String,
      required: true
    },
    /**
     * Disables the return date when looking for a one way flight
     */
    oneWay: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Label for the rountrip checkbox
     */
    roundtripLabel: {
      type: String,
      required: true
    },
    /**
     * Label for the one way checkbox
     */
    oneWayLabel: {
      type: String,
      required: true
    },
    /**
     * Text for the search button
     */
    buttonText: {
      type: String,
      required: true
    },
    /**
     * Suggestions for origin autocomplete
     */
    originItems: {
      type: Array as PropType<AutocompleteItem[]>,
      required: false,
      default: () => []
    },
    /**
     * Suggestions for destination autocomplete
     */
    destinationItems: {
      type: Array as PropType<AutocompleteItem[]>,
      required: false,
      default: () => []
    },
    /**
     * Time in ms for the component to wait before triggering the search event
     */
    debounceTime: {
      type: Number,
      required: false,
      default: 500
    },
    /**
     * Minimum characters needed before triggering the search event
     */
    minChars: {
      type: Number,
      required: false,
      default: 2
    },
    /**
     * Date format for the datepicker
     */
    dateFormat: {
      type: String,
      required: false,
      default: undefined
    },
    /**
     * Date to disable in the datepicker. ISO Format
     */
    disabledDates: {
      type: Array as PropType<string[] | Date[]>,
      required: false,
      default: () => []
    },
    /**
     * Set min date to outbound date picker.
     */
    outboundMinDate: {
      type: String,
      required: false,
      default: () => format(new Date(), DateFormat.ISO)
    },
    /**
     * Set max date to outbound date picker.
     */
    outboundMaxDate: {
      type: String,
      required: false,
      default: undefined
    },
    /**
     * Set min date to inbound date picker.
     */
    inboundMinDate: {
      type: String,
      required: false,
      default: () => format(new Date(), DateFormat.ISO)
    },
    /**
     * Set max date to inbound date picker.
     */
    inboundMaxDate: {
      type: String,
      required: false,
      default: undefined
    },
    /**
     * Initial value for the origin field
     */
    originValue: {
      type: Object as PropType<AutocompleteItem>,
      required: false,
      default: null
    },
    /**
     * Initial value for the destination field
     */
    destinationValue: {
      type: Object as PropType<AutocompleteItem>,
      required: false,
      default: null
    },
    /**
     * Initial value for the departure date field
     */
    departureValue: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Initial value for the return date field
     */
    returnValue: {
      type: String,
      required: false,
      default: null
    }
  },

  data(): SearchFlightData {
    return {
      tripItems: [
        { id: TripType.ROUNDTRIP, name: this.roundtripLabel },
        { id: TripType.ONE_WAY, name: this.oneWayLabel }
      ],
      oneWayId: TripType.ONE_WAY,
      searchData: {
        origin: this.originValue,
        destination: this.destinationValue,
        departure: this.departureValue,
        return: this.returnValue,
        tripType: null
      }
    };
  },

  computed: {
    isButtonDisabled(): boolean {
      const hasOrigin = !!this.searchData.origin;
      const hasDestination = !!this.searchData.destination;
      const hasDeparture = !!this.searchData.departure;
      const hasReturn = !this.searchData.tripType
        ? !!this.searchData.return
        : true;
      return !hasOrigin || !hasDestination || !hasDeparture || !hasReturn;
    },

    departureDate(): Date | null {
      return this.searchData.departure
        ? parse(this.searchData.departure, DateFormat.ISO, new Date())
        : null;
    },

    returnDate(): Date | null {
      return this.searchData.return
        ? parse(this.searchData.return, DateFormat.ISO, new Date())
        : null;
    },

    getOutboundMaxDate(): string | null {
      let maxDate = null;
      if (this.outboundMaxDate) {
        const outboundMaxDate = parse(
          this.outboundMaxDate,
          DateFormat.ISO,
          new Date()
        );
        maxDate =
          this.returnDate && isBefore(this.returnDate, outboundMaxDate)
            ? this.searchData.return
            : this.outboundMaxDate;
      }
      return maxDate || this.outboundMaxDate;
    },

    getInboundMinDate(): string | null {
      let minDate = null;

      if (this.inboundMinDate) {
        const inboundMinDate = parse(
          this.inboundMinDate,
          DateFormat.ISO,
          new Date()
        );
        minDate =
          this.departureDate && isAfter(this.departureDate, inboundMinDate)
            ? this.searchData.departure
            : this.inboundMinDate;
      }

      return minDate || this.inboundMaxDate;
    }
  },

  watch: {
    oneWay(newValue: boolean): void {
      this.setTripType(newValue);
    },

    originValue(newValue: AutocompleteItem): void {
      this.searchData.origin = newValue;
    },

    destinationValue(newValue: AutocompleteItem): void {
      this.searchData.destination = newValue;
    },

    departureValue(newValue: string | null): void {
      this.searchData.departure = newValue;
    },

    returnValue(newValue: string | null): void {
      this.searchData.return = newValue;
    }
  },

  mounted() {
    this.setTripType(this.oneWay);
  },

  methods: {
    setTripType(value: boolean): void {
      const type = value ? TripType.ONE_WAY : TripType.ROUNDTRIP;
      this.searchData.tripType =
        this.tripItems.find(trip => trip.id === type) || null;
    },

    /**
     * @description
     * Emits an event to let the parent component
     * handle the API call to load suggestions for origin
     */
    handleOriginSearch($event: string): void {
      const cityQuery: CityQuery = {
        destination: this.searchData.destination,
        query: $event
      };
      /**
       * Event emitted when typing in the origin city input after the debounce time.
       * It provides the input value `<CityQuery>`
       * @event cvo-search-flight-origin
       */
      this.$emit(Events.CVO_SEARCH_FLIGHT_ORIGIN, cityQuery);
    },

    /**
     * @description
     * Emits an event to let the parent component
     * handle the API call to load suggestions for destination
     */
    handleDestinationSearch($event: string): void {
      const cityQuery: CityQuery = {
        origin: this.searchData.origin,
        query: $event
      };
      /**
       * Event emitted when typing in the destination city input after the debounce time.
       * It provides the input value `<CityQuery>`
       * @event cvo-search-flight-destination
       */
      this.$emit(Events.CVO_SEARCH_FLIGHT_DESTINATION, cityQuery);
    },

    /**
     * @description
     * Emits an event to let the parent component
     * handle the flight search
     */
    handleSubmit(): void {
      const payload = {
        ...this.searchData,
        departure: this.departureDate
          ? format(this.departureDate, DateFormat.ISO)
          : null,
        return: this.returnDate ? format(this.returnDate, DateFormat.ISO) : null
      };

      /**
       * Event emitted when clicking on the search button.
       * It provides the payload `<SearchData>`
       * @event cvo-search-flight-submit
       */
      this.$emit(Events.CVO_SEARCH_FLIGHT_SUBMIT, payload);
    },

    getDepartureClasses(cellDate: Date) {
      const classes = [];
      const returnDate = this.returnDate;
      if (returnDate && isSameDay(cellDate, returnDate)) {
        classes.push(Classes.IS_RETURN);
      } else if (this.isInSelectedRange(cellDate)) {
        classes.push(Classes.SELECTED_RANGE);
      }
      return classes;
    },

    getReturnClasses(cellDate: Date) {
      const classes = [];
      const departureDate = this.departureDate;
      if (departureDate && isSameDay(cellDate, departureDate)) {
        classes.push(Classes.IS_DEPARTURE);
      } else if (this.isInSelectedRange(cellDate)) {
        classes.push(Classes.SELECTED_RANGE);
      }
      return classes;
    },

    isInSelectedRange(date: Date): boolean {
      return !!(
        this.departureDate &&
        this.returnDate &&
        isWithinInterval(date, {
          start: this.departureDate,
          end: this.returnDate
        })
      );
    },

    setDepartureListeners(): void {
      this.searchData.return && this.setListeners(isAfter);
    },

    setReturnListeners(): void {
      this.searchData.departure && this.setListeners(isBefore);
    },

    setListeners(isInRange: Function): void {
      setTimeout(() => {
        const cells = document.querySelectorAll(CELLS_SELECTOR);
        cells.forEach(cell => {
          cell.addEventListener("mouseover", (event: Event) => {
            const date = (event.currentTarget as HTMLElement).title;
            this.setRangeClass(cells, date, isInRange);
          });
        });
      }, 0);
    },

    setRangeClass(cells: NodeList, date: string, isInRange: Function): void {
      cells.forEach(cellNode => {
        const cell = cellNode as HTMLElement;
        if (
          isInRange(parse(cell.title, "yyyy-MM-dd", new Date()), new Date(date))
        ) {
          cell.classList.add(Classes.IN_RANGE);
        } else {
          cell.classList.remove(Classes.IN_RANGE);
        }
      });
    }
  }
});
