var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"CvoDefinitionList"},[_c('ul',{staticClass:"CvoDefinitionList-list"},_vm._l((_vm.items),function(item,i){return _c('li',{key:i,staticClass:"CvoDefinitionList-item"},[_c('div',{staticClass:"CvoDefinitionList-term"},[_vm._v(_vm._s(item.term))]),_c('div',{staticClass:"CvoDefinitionList-definitionGroup"},[(typeof item.definition === 'string')?[_c('p',{staticClass:"CvoDefinitionList-definition"},[_vm._v(_vm._s(item.definition))])]:(
            Array.isArray(item.definition) &&
              typeof item.definition[0] === 'string'
          )?_vm._l((item.definition),function(definition,j){return _c('p',{key:j,staticClass:"CvoDefinitionList-definition"},[_vm._v(" "+_vm._s(definition)+" ")])}):(
            typeof item.definition === 'object' &&
              !(item.definition instanceof Array)
          )?[_c('p',{staticClass:"CvoDefinitionList-definition"},[(item.definition.icon)?_c('span',{staticClass:"CvoDefinitionList-definitionIcon",class:item.definition.icon}):_vm._e(),_c('span',{staticClass:"CvoDefinitionList-definitionValue"},[_vm._v(" "+_vm._s(item.definition.value)+" ")])])]:(
            Array.isArray(item.definition) &&
              typeof item.definition[0] === 'object'
          )?_vm._l((item.definition),function(definition,j){return _c('p',{key:j,staticClass:"CvoDefinitionList-definition"},[(definition.icon)?_c('span',{staticClass:"CvoDefinitionList-definitionIcon",class:definition.icon}):_vm._e(),_c('span',{staticClass:"CvoDefinitionList-definitionValue"},[_vm._v(" "+_vm._s(definition.value)+" ")])])}):_vm._e()],2)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }