















































































import Vue, { PropType } from "vue";
import CvoFlightDateSelector from "@/components/FlightDateSelector/FlightDateSelector.vue";
import {
  FlightDateSelectorInterface,
  FlightDateSelectorType,
  FlightRowItem
} from "@/models";
import Events from "@/constants/events";
import { isBefore, isAfter, parse } from "date-fns";
import { DateFormat } from "@/models";

export default Vue.extend({
  name: "CvoSearchResult",
  components: { CvoFlightDateSelector },

  props: {
    /**
     * An object of FlightDateSelectorInterface type with the required
     * properties for the outbound Flight Date Selector component
     */
    outboundDateSelectorProps: {
      type: Object as PropType<FlightDateSelectorInterface>,
      required: true
    },
    /**
     * An object of FlightDateSelectorInterface type with the required
     * properties for the inbound Flight Date Selector component
     */
    inboundDateSelectorProps: {
      type: Object as PropType<FlightDateSelectorInterface>,
      required: false,
      default: null
    },
    /**
     * Array of objects (outbound flights) that should match the required properties
     * for the components passed in the flight slot
     */
    outboundFlights: {
      type: Array as PropType<FlightRowItem[]>,
      required: true
    },
    /**
     * Array of objects (inbound flights) that should match the required properties
     * for the components passed in the flight slot
     */
    inboundFlights: {
      type: Array as PropType<FlightRowItem[]>,
      required: false,
      default: null
    },
    /**
     * Objects that should match the required properties
     * for the components passed in the flight slot
     */
    selectedOutboundFlight: {
      type: Object as PropType<FlightRowItem>,
      required: false,
      default: undefined
    },
    /**
     * Objects that should match the required properties
     * for the components passed in the flight slot
     */
    selectedInboundFlight: {
      type: Object as PropType<FlightRowItem>,
      required: false,
      default: undefined
    },
    /**
     * Whether to show the date selector and results for
     * one way or roundtrip flights
     */
    oneWay: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      outboundDate: this.outboundDateSelectorProps.initialDate,
      inboundDate: !this.oneWay
        ? this.inboundDateSelectorProps.initialDate
        : null,
      hasOutboundResults: this.outboundFlights && !!this.outboundFlights.length,
      hasInboundResults: this.inboundFlights && !!this.inboundFlights.length,
      outboundSelectorType: FlightDateSelectorType.OUTBOUND,
      inboundSelectorType: FlightDateSelectorType.INBOUND
    };
  },
  computed: {
    inboundMinDate(): string | null {
      const date = this.selectedOutboundFlight
        ? this.outboundDate
        : this.inboundDateSelectorProps.minDate;

      return date || null;
    },

    parsedOutboundDate(): Date {
      return parse(this.outboundDate, DateFormat.ISO, new Date());
    },

    parsedInboundDate(): Date | null {
      return this.inboundDate
        ? parse(this.inboundDate, DateFormat.ISO, new Date())
        : null;
    }
  },
  watch: {
    outboundFlights(newVal: FlightRowItem[]): void {
      this.hasOutboundResults = newVal.length ? true : false;
    },
    inboundFlights(newVal: FlightRowItem[]): void {
      this.hasOutboundResults = newVal.length ? true : false;
    }
  },
  methods: {
    handleOutboundDateSelect($event: string): void {
      this.outboundDate = $event;
      if (this.parsedInboundDate && !this.oneWay) {
        this.inboundDate = isAfter(
          this.parsedOutboundDate,
          this.parsedInboundDate
        )
          ? this.outboundDate
          : this.inboundDate;
      }

      this.emitSelectionEvent();
    },

    handleInboundDateSelect($event: string): void {
      this.inboundDate = $event;
      if (this.parsedInboundDate) {
        this.outboundDate = isBefore(
          this.parsedInboundDate,
          this.parsedOutboundDate
        )
          ? this.inboundDate
          : this.outboundDate;
      }

      this.emitSelectionEvent();
    },

    emitSelectionEvent(): void {
      /**
       * Emits an event when a date has been selected
       *
       * @event cvo-search-result-date-select
       */
      this.$emit(Events.CVO_SEARCH_RESULT_DATE_SELECT, {
        departure: this.outboundDate,
        return: this.inboundDate
      });
    }
  }
});
