

























import Vue, { PropType, VueConstructor } from "vue";
import DateMixin from "@/mixins/Date/Date.mixin";
import DatePicker from "vue2-datepicker";
import Events from "@/constants/events";
import DatepickerData, { DatepickerLangInterface } from "./model";
import { format, parse, isBefore } from "date-fns";
import { DateFormat } from "@/models";

const ExtendedVue = Vue as VueConstructor<Vue & InstanceType<typeof DateMixin>>;

export default ExtendedVue.extend({
  name: "CvoDatepicker",

  components: {
    DatePicker
  },

  mixins: [DateMixin],

  model: {
    prop: "value",
    event: Events.CVO_DATEPICKER_CHANGE
  },

  props: {
    /**
     * Field label
     */
    label: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Placeholder for the input element
     */
    placeholder: {
      type: String,
      required: true
    },
    /**
     * Sets the first day of the week, starting with 0 for Sunday
     */
    firstDayOfWeek: {
      type: Number,
      required: false,
      default: 0
    },
    /**
     * Disables the datepicker
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Sets initial value
     */
    value: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Sets the locale
     */
    locale: {
      type: Object as PropType<DatepickerLangInterface>,
      required: false,
      default: () => {
        return {};
      }
    },
    /**
     * Fn
     */
    getClasses: {
      type: Function,
      required: false,
      default: undefined
    },
    /**
     * ISO String date to use as starting point when opening the popup
     */
    defaultValue: {
      type: String,
      required: false,
      default: ""
    }
  },

  data(): DatepickerData {
    return {
      /**
       * Due to timezone issues, the model `date` will be string type
       * (It will be the value of the input, so the date format will
       * be based on `dateFormat`)
       */
      date: null,
      lang: {
        formatLocale: {
          firstDayOfWeek: this.firstDayOfWeek
        },
        ...this.locale
      }
    };
  },

  computed: {
    getInputId(): string {
      return this.label.replaceAll(" ", "-");
    },

    getDefaultValue(): string {
      const parsedDefault = this.defaultValue
        ? parse(this.defaultValue, DateFormat.ISO, new Date())
        : new Date();

      let result;
      if (this.parsedMinDate && isBefore(parsedDefault, this.parsedMinDate)) {
        result = this.minDate;
      } else {
        result = format(parsedDefault, DateFormat.ISO);
      }

      return result;
    }
  },

  watch: {
    value(newVal) {
      this.date = newVal ? this.isoStringToDateFormat(newVal) : null;
    }
  },

  mounted(): void {
    this.date = this.value ? this.isoStringToDateFormat(this.value) : null;
    this.setInputClasses();
  },

  methods: {
    /**
     * @description
     * Emits an event when a date is selected
     */
    handleChange($event: string | null): void {
      /**
       * Event emitted when selecting a date or clearing the selection
       * It provides the input value `<string | null>`
       * @event cvo-datepicker-change
       */
      const parsed = $event
        ? parse($event as string, this.dateFormat, new Date())
        : null;
      this.emitDateEvent(Events.CVO_DATEPICKER_CHANGE, parsed);
    },

    handleOpen(): void {
      /**
       * Event emitted when datepicker is open
       *
       * @event cvo-datepicker-open
       */
      this.$emit(Events.CVO_DATEPICKER_OPEN);
    },

    handleCalendarChange($event: Date | null): void {
      /**
       * Event emitted when change month or year in the calendar
       * It provides the first day in the new displayed page `<Date>`
       * @event cvo-datepicker-calendar-change
       */
      this.emitDateEvent(Events.CVO_DATEPICKER_CALENDAR_CHANGE, $event);
    },

    setInputClasses(): void {
      const datepicker = this.$refs.datepicker as Vue & {
        $el: HTMLElement;
      };
      const input = datepicker.$el.querySelector("input");
      input?.classList.add("CvoForm-field");
      input?.classList.add("input");
      this.label && input?.setAttribute("id", this.getInputId);
    },

    // Method used by the datepicker formatter
    stringify(date: Date): string | null {
      return date ? format(date, this.dateFormat) : null;
    },

    // Method used by the datepicker formatter
    parse(dateString: string): Date | null {
      return dateString ? parse(dateString, this.dateFormat, new Date()) : null;
    },

    isoStringToDateFormat(dateString: string): string | null {
      let formatted = null;
      if (dateString) {
        const parsed = parse(dateString, DateFormat.ISO, new Date());
        formatted = format(parsed, this.dateFormat);
      }
      return formatted;
    }
  }
});
