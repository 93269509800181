















































































































import Vue, { VueConstructor } from "vue";
import CartMixin from "@/mixins/Cart/Cart.mixin";
import MiniCartData from "./model";
import Events from "@/constants/events";

const ExtendedVue = Vue as VueConstructor<Vue & InstanceType<typeof CartMixin>>;

export default ExtendedVue.extend({
  name: "CvoMiniCart",
  mixins: [CartMixin],

  props: {
    /**
     * Title for the flight description
     */
    flightDescriptionTitle: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Flight description
     */
    flightDescriptionValue: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Text for the items summary
     */
    summaryTitle: {
      type: String,
      required: false,
      default: ""
    },

    /**
     * Subscription type, ie: monthly
     */
    subscriptionType: {
      type: String,
      required: false,
      default: ""
    },

    /**
     * Text for continue button
     */
    continueButtonText: {
      type: String,
      required: false,
      default: ""
    }
  },

  data(): MiniCartData {
    return {
      miniCartEl: null,
      panelEl: null,
      panelPosition: null
    };
  },

  mounted(): void {
    this.panelEl = this.$refs.miniCartPanel as HTMLElement;
    this.miniCartEl = this.$refs.miniCart as HTMLElement;

    document.querySelectorAll("html, body")?.forEach(element => {
      element.addEventListener("click", e => {
        this.toggleCart(e, false);
      });
    });

    this.panelEl.addEventListener("click", e => {
      e.stopPropagation();
    });
  },

  methods: {
    /**
     * @description
     * Shows / hide the mini cart panel
     */
    toggleCart(e: Event, toggle: boolean): void {
      e.stopPropagation();

      toggle =
        typeof toggle !== "undefined"
          ? toggle
          : !this.miniCartEl?.classList.contains("is-expanded");

      this.miniCartEl?.classList.toggle("is-expanded", toggle);

      this.setPanelPosition(toggle);
    },

    /**
     * @description
     * Adds / removes the right position when the panel is offscreen
     */
    setPanelPosition(isVisible: boolean): void {
      if (isVisible) {
        this.panelPosition = this.isOffScreen() ? 0 : null;
      } else {
        this.panelPosition = null;
      }
    },

    /**
     * @description
     * Checks if the panel is off the screen
     */
    isOffScreen(): boolean {
      if (this.panelEl) {
        const rightPosition = this.panelEl.getBoundingClientRect().right;
        return window.innerWidth <= rightPosition;
      } else {
        return false;
      }
    },

    /**
     * @description
     * Emits an event when the continue button is clicked
     */
    handleContinue(): void {
      /**
       * Event emitted to continue with the checkout
       * @event cvo-cart-continue
       */
      this.$emit(Events.CVO_CART_CONTINUE);
    }
  }
});
