



















import Vue from "vue";
import FooterMixin from "@/mixins/Footer/Footer.mixin";

export default Vue.extend({
  name: "CvoSimpleFooter",
  mixins: [FooterMixin]
});
