








































































import Vue, { PropType, VueConstructor } from "vue";
import CvoStepsBar from "@/components/StepsBar/StepsBar.vue";
import CvoSimpleCart from "@/components/SimpleCart/SimpleCart.vue";
import CvoToggleButton from "@/components/ToggleButton/ToggleButton.vue";
import { ValidationObserver } from "vee-validate";
import {
  ConfigurePlanPayload,
  LocaleCode,
  StepsBarItem,
  ToggleButtonItem
} from "@/models";
import Events from "@/constants/events";
import FormMixin from "@/mixins/Form/Form.mixin";
import ConfigurePlanPageData from "./model";

const ExtendedVue = Vue as VueConstructor<Vue & InstanceType<typeof FormMixin>>;

export default ExtendedVue.extend({
  name: "CvoConfigurePlanPage",
  components: {
    CvoStepsBar,
    CvoSimpleCart,
    CvoToggleButton,
    ValidationObserver
  },
  mixins: [FormMixin],
  props: {
    /**
     * List of steps to show in the CvoStepBar
     */
    steps: {
      type: Array as PropType<StepsBarItem[]>,
      required: true
    },
    /**
     * Title for the page
     */
    pageTitle: {
      type: String,
      required: true
    },
    /**
     * Title for the plan section
     */
    planSectionTitle: {
      type: String,
      required: true
    },
    /**
     * Description for the plan section
     */
    planSectionDescription: {
      type: String,
      required: true
    },
    /**
     * Title for the cycle section
     */
    cycleSectionTitle: {
      type: String,
      required: true
    },
    /**
     * Description for the cycle section
     */
    cycleSectionDescription: {
      type: String,
      required: true
    },
    /**
     * Items for the plan selection toggle button
     */
    plansList: {
      type: Array as PropType<ToggleButtonItem[]>,
      required: true
    },
    /**
     * Items for the cycle selection toggle button
     */
    cycleList: {
      type: Array as PropType<ToggleButtonItem[]>,
      required: true
    },
    /**
     * Cart props. Please refer to
     * https://design-system.caravelo.net/cvo-vue-library/storybook/?path=/story/checkout-cart-simplecart
     */
    cartProps: {
      type: Object,
      required: true
    },
    /**
     * Text for the submit button
     */
    submitText: {
      type: String,
      required: true
    },
    /**
     * The id of the plan to be selected by default
     */
    defaultPlanId: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * The id of the cycle to be selected by default
     */
    defaultCycleId: {
      type: String,
      required: false,
      default: ""
    }
  },
  data(): ConfigurePlanPageData {
    return {
      plan: this.plansList.find(plan => plan.id === this.defaultPlanId) || null,
      cycle:
        this.cycleList.find(cicle => cicle.id === this.defaultCycleId) || null
    };
  },
  created(): void {
    /**
     * Configure the form validator
     * Note: should set the locale for the error messages
     */
    this.formValidator(this.errorsLocale as LocaleCode);
  },
  methods: {
    /**
     * Handles submit form
     */
    onSubmit(): void {
      /**
       * Emits the plan data when the user submits the form `ConfigurePlanPayload`
       *
       * @event cvo-configure-plan-page-submit
       * @property {ConfigurePlanPayload}
       */
      this.$emit(Events.CVO_CONFIGURE_PLAN_PAGE_SUBMIT, {
        plan: this.plan?.id,
        cycle: this.cycle?.id
      } as ConfigurePlanPayload);
    },

    handlePlanSelection(plan: ToggleButtonItem): void {
      this.cycle = null;
      /**
       * Emits the selected plan `ToggleButtonItem`
       *
       * @event cvo-configure-plan-page-plan-change
       * @property {ToggleButtonItem} plan
       */
      this.$emit(Events.CVO_CONFIGURE_PLAN_PAGE_PLAN_CHANGE, plan);
    },

    handleCycleSelection(plan: ToggleButtonItem): void {
      /**
       * Emits the selected plan `ToggleButtonItem`
       *
       * @event cvo-configure-plan-page-cycle-change
       * @property {ToggleButtonItem} plan
       */
      this.$emit(Events.CVO_CONFIGURE_PLAN_PAGE_CYCLE_CHANGE, plan);
    }
  }
});
