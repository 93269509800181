































































































import Vue, { PropType, VueConstructor } from "vue";
import { ValidationObserver } from "vee-validate";
import Events from "@/constants/events";
import { InputFieldType, LoginCredentials } from "./models";
import CvoInputField from "@/components/InputField/InputField.vue";
import CvoPasswordField from "@/components/PasswordField/PasswordField.vue";
import FormMixin from "@/mixins/Form/Form.mixin";
import { LinkItem, LocaleCode } from "@/models";

// Input types
const inputTypeEmail = "email";
const inputTypeText = "text";

// Types by field
const firstInputFieldTypes: InputFieldType[] = [inputTypeText, inputTypeEmail];

const ExtendedVue = Vue as VueConstructor<Vue & InstanceType<typeof FormMixin>>;

export default ExtendedVue.extend({
  name: "CvoLogin",

  components: {
    ValidationObserver,
    CvoInputField,
    CvoPasswordField
  },

  mixins: [FormMixin],

  props: {
    /**
     * First input field type
     * Options: "text" | "email" | "password"
     */
    firstInputFieldType: {
      required: false,
      type: String,
      default: "email",
      validator: function(value: InputFieldType) {
        return firstInputFieldTypes.indexOf(value) !== -1;
      }
    },
    /**
     * First input field label
     */
    firstInputFieldLabel: {
      required: true,
      type: String
    },
    /**
     * First input field name
     */
    firstInputFieldName: {
      required: true,
      type: String
    },
    /**
     * First input field placeholder
     */
    firstInputFieldPlaceholder: {
      required: false,
      type: String,
      default: ""
    },
    /**
     * Second input field label
     */
    secondInputFieldLabel: {
      required: true,
      type: String
    },
    /**
     * Second input field name
     */
    secondInputFieldName: {
      required: true,
      type: String
    },
    /**
     * Second input field placeholder
     */
    secondInputFieldPlaceholder: {
      required: false,
      type: String,
      default: ""
    },
    /**
     * Button text
     */
    buttonText: {
      required: false,
      type: String,
      default: "Login"
    },
    /**
     * Object the forgot user link
     */
    forgotUser: {
      required: false,
      type: Object as PropType<LinkItem>,
      default: null
    },
    /**
     * Object for the forgot password link
     */
    forgotPassword: {
      required: false,
      type: Object as PropType<LinkItem>,
      default: null
    },
    /**
     * Whether the forgot links emit an event on clicking
     * or navigates to the link.
     */
    emitEvent: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      firstInputField: "",
      secondInputField: ""
    };
  },

  created() {
    this.configureFormValidator();
  },

  methods: {
    /**
     * Configure the form validator
     * Note: should set the locale for the error messages
     */
    configureFormValidator(): void {
      this.formValidator(this.errorsLocale as LocaleCode);
    },

    /**
     * Get login credentials
     *
     * @returns {LoginCredentials}
     */
    getLoginCredentials(): LoginCredentials {
      return {
        firstInputField: this.firstInputField,
        secondInputField: this.secondInputField
      };
    },

    /**
     * Handle form submit
     */
    onSubmit(): void {
      ((this.$refs.observer as unknown) as { reset(): void }).reset();

      /**
       * Emits the login credentials when the user submits the form
       *
       * @event cvo-login-submit
       * @property {LoginCredentials} loginCredentials
       */
      this.$emit(Events.CVO_LOGIN_SUBMIT, this.getLoginCredentials());
    },

    handleForgotClick(link: string, event: Event): void {
      event.preventDefault();
      /**
       * Event emitted when the `emit-event` prop is `true`.
       * Lets the parent handle the navigation.
       * It provides the link `string`.
       * @event cvo-login-forgot-click
       */
      this.$emit(Events.CVO_LOGIN_FORGOT_CLICK, link);
    }
  }
});
