




















































import Vue, { PropType } from "vue";
import Events from "@/constants/events";

export default Vue.extend({
  name: "CvoInformationCard",
  props: {
    /**
     * Card title
     */
    title: {
      type: String,
      required: true
    },

    /**
     * Card content. String or array of strings that will be rendered as paragraphs
     */
    content: {
      type: [String, Array] as PropType<string | string[]>,
      required: false,
      default: () => []
    },

    /**
     * Text for the action button
     */
    actionText: {
      type: String,
      required: false,
      default: ""
    },

    /**
     * Text for the secondary action
     */
    secondaryActionText: {
      type: String,
      required: false,
      default: ""
    }
  },
  methods: {
    handleActionClick() {
      /**
       * Event emitted to apply the coupon code
       * @event cvo-information-card-action-click
       */
      this.$emit(Events.CVO_INFORMATION_CARD_ACTION_CLICK);
    },
    handleSecondaryActionClick() {
      /**
       * Event emitted to apply the coupon code
       * @event cvo-information-card-secondario-action-click
       */
      this.$emit(Events.CVO_INFORMATION_CARD_SECONDARY_ACTION_CLICK);
    }
  }
});
