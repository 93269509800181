














































































import Vue, { PropType } from "vue";
import CvoStepsBar from "@/components/StepsBar/StepsBar.vue";
import CvoPurchaseConfirmation from "@/components/PurchaseConfirmation/PurchaseConfirmation.vue";
import CvoInformationCard from "@/components/InformationCard/InformationCard.vue";
import CvoDefinitionList from "@/components/DefinitionList/DefinitionList.vue";
import {
  DefinitionListInterface,
  InformationCardInterface,
  PurchaseConfirmationInterface,
  StepsBarItem
} from "@/models";
import Events from "@/constants/events";

export default Vue.extend({
  name: "CvoConfirmationPage",
  components: {
    CvoStepsBar,
    CvoPurchaseConfirmation,
    CvoInformationCard,
    CvoDefinitionList
  },
  props: {
    /**
     * List of steps to show in the CvoStepBar
     */
    steps: {
      type: Array as PropType<StepsBarItem[]>,
      required: true
    },
    /**
     * Props for the purchase confirmation component.
     * Refer to: https://design-system.caravelo.net/cvo-vue-library/storybook/?path=/story/purchaseconfirmation--default
     */
    purchaseConfirmationProps: {
      type: Object as PropType<PurchaseConfirmationInterface>,
      required: true
    },
    /**
     * Props for the card containing plan info
     * Refer to: https://design-system.caravelo.net/cvo-vue-library/storybook/?path=/story/information-card--default-information-card
     */
    planCardProps: {
      type: Object as PropType<InformationCardInterface>,
      required: true
    },
    /**
     * Props for the card containing payment info
     * Refer to: https://design-system.caravelo.net/cvo-vue-library/storybook/?path=/story/information-card--default-information-card
     */
    paymentCardProps: {
      type: Object as PropType<InformationCardInterface>,
      required: true
    },
    /**
     * Props for the card containing beneficiary info
     * Refer to: https://design-system.caravelo.net/cvo-vue-library/storybook/?path=/story/information-card--default-information-card
     */
    beneficiaryCardProps: {
      type: Object as PropType<InformationCardInterface>,
      required: true
    },
    /**
     * Props for the credit card summary
     * Refer to: https://design-system.caravelo.net/cvo-vue-library/storybook/?path=/story/definition-list--default-definition-list
     */
    creditCardSummaryProps: {
      type: Object as PropType<DefinitionListInterface>,
      required: true
    }
  },
  data() {
    return {
      events: Events
    };
  },
  methods: {
    emitEvent(eventName: string) {
      /**
       * Emits the event name provided
       *
       * @event cvo-profile-overview-*
       * @property {string} eventName
       */
      this.$emit(eventName);
    }
  }
});
