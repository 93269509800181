























































































































































import Vue, { PropType, VueConstructor } from "vue";
import CartMixin from "@/mixins/Cart/Cart.mixin";

const ExtendedVue = Vue as VueConstructor<Vue & InstanceType<typeof CartMixin>>;

export default ExtendedVue.extend({
  name: "CvoImmersiveCart",
  mixins: [CartMixin],
  props: {
    /**
     * Array of images url to show on the upper section
     */
    images: {
      type: Array as PropType<string[]>,
      required: true
    }
  }
});
