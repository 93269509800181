


































































































import Vue, { PropType } from "vue";
import CvoCardAdyen from "@/components/CardAdyen/CardAdyen.vue";
import Events from "@/constants/events";
import { StoredCardAdyen } from "@/models";

export default Vue.extend({
  name: "CvoEditCardAdyen",
  components: { CvoCardAdyen },
  model: {
    prop: "isEditMode",
    event: Events.CVO_EDIT_CARD_ADYEN_EDIT_MODE
  },
  props: {
    /**
     * Label for the credit card input
     */
    creditCardLabel: {
      type: String,
      required: true
    },
    /**
     * Label for the expiration date input
     */
    expiryDateLabel: {
      type: String,
      required: true
    },
    /**
     * Label for the cvv code
     */
    cvvLabel: {
      type: String,
      required: true
    },
    /**
     * Label for the card holder
     */
    holderLabel: {
      type: String,
      required: true
    },
    /**
     * Placeholder for the card holder
     */
    holderPlaceholder: {
      type: String,
      required: true
    },
    /**
     * Content for the cvv tooltip message
     */
    tooltipMsg: {
      type: String,
      required: true
    },
    /**
     * Text for the save button
     */
    saveText: {
      type: String,
      required: true
    },
    /**
     * Text for the edit button
     */
    editText: {
      type: String,
      required: true
    },
    /**
     * Text for the cancel button
     */
    cancelText: {
      type: String,
      required: true
    },
    /**
     * v-model, Whether the component is in edit mode or not
     */
    isEditMode: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Object with the stored card provided by Adyen
     */
    storedCard: {
      type: Object as PropType<StoredCardAdyen>,
      required: false,
      default: null
    },
    /**
     *  Text for the card holder on the card display
     */
    cardHolderText: {
      type: String,
      required: false,
      default: "Card Holder"
    },
    /**
     *  Text for the expiration date on the card display
     */
    expirationText: {
      type: String,
      required: false,
      default: "Expires"
    },
    /**
     * Text for the terms and conditions disclaimer
     */
    termsText: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      editMode: this.isEditMode
    };
  },
  computed: {
    cardPattern(): string {
      let pattern;
      switch (this.storedCard.brand) {
        case "amex":
          pattern = "**** ****** *";
          break;

        case "diners":
          pattern = "**** ****** ";
          break;

        default:
          pattern = "**** **** **** ";
          break;
      }
      return pattern;
    }
  },
  watch: {
    /**
     * Updates the edit state when prop changes
     * @param {newValue} boolean
     */
    isEditMode(newValue: boolean): void {
      this.editMode = newValue;
    }
  },
  methods: {
    editCard() {
      this.editMode = true;
      this.updateModel(this.editMode);
    },

    cancelEdit() {
      this.editMode = false;
      this.updateModel(this.editMode);
    },

    saveCard() {
      const holderName = (this.$refs.cardAdyenFields as Vue & {
        holderName: string;
      }).holderName;
      /**
       * Event to save the card edition
       *
       * @event cvo-edit-card-adyen-save
       */
      this.$emit(Events.CVO_EDIT_CARD_ADYEN_SAVE, holderName);
    },

    updateModel(editMode: boolean): void {
      /**
       * v-model Event to update the edit mode model
       *
       * @event cvo-edit-card-adyen-edit-mode
       */
      this.$emit(Events.CVO_EDIT_CARD_ADYEN_EDIT_MODE, editMode);
    }
  }
});
