








































import Vue, { VueConstructor } from "vue";
import FormElementMixin from "@/mixins/FormElement/FormElement.mixin";
import Events from "@/constants/events";
import { CheckboxData } from "./model";

const ExtendedVue = Vue as VueConstructor<
  Vue & InstanceType<typeof FormElementMixin>
>;

export default ExtendedVue.extend({
  name: "CvoCheckbox",

  mixins: [FormElementMixin],

  model: {
    prop: "checked",
    event: Events.CVO_CHECKBOX_CHANGE
  },

  props: {
    /**
     * Whether the checkbox is checked
     */
    checked: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * Label for the checkbox (not required since a slot can be provided)
     */
    label: {
      type: String,
      required: false,
      default: null
    }
  },

  data(): CheckboxData {
    return {
      currentValue: this.checked
    };
  },

  watch: {
    /**
     * Updates the value when prop changes
     * @param {newValue} string
     */
    checked(newValue: boolean): void {
      this.currentValue = newValue;
    }
  },

  methods: {
    handleChange(): void {
      /**
       * Emits the checked status of the checkbox
       *
       * @event cvo-checkbox-change
       * @property {boolean}
       */
      this.$emit(Events.CVO_CHECKBOX_CHANGE, this.currentValue);
    },
    /**
     * Sets the proper classes
     * @param {object} classes Vee Validate classes https://vee-validate.logaretm.com/v3/guide/state.html#css-classes
     * @returns {object} new object with classes
     */
    setupClasses(classes: object): object {
      const newClasses = {
        ...classes,
        checkbox: true,
        "CvoCheckbox-input": true
      };
      return this.getClasses(newClasses);
    }
  }
});
