




























import Events from "@/constants/events";
import Vue from "vue";

export default Vue.extend({
  name: "CvoAddon",

  props: {
    /**
     * Addon id
     */
    addonId: {
      type: [String, Number],
      required: true
    },
    /**
     * Path to the image
     */
    imageSrc: {
      type: String,
      required: false,
      default: null
    },

    /**
     * Text for the action button
     */
    actionText: {
      type: String,
      required: false,
      default: ""
    }
  },

  methods: {
    /**
     * Emits an event when the action button is clicked
     */
    handleAction() {
      /**
       * Emits the login credentials when the user submits the form
       *
       * @event cvo-addon-action
       * @property {string | number} addonId
       */
      this.$emit(Events.CVO_ADDON_ACTION, this.addonId);
    }
  }
});
