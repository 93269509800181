






























import Events from "@/constants/events";
import Vue from "vue";
export default Vue.extend({
  name: "CvoPurchaseConfirmation",
  props: {
    /**
     * A text to confirm that the purchase was ok
     */
    successMessage: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * A piece of text to be highlighted. For example the plan name
     */
    title: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * A description message of the purchase
     */
    text: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * The cost of the transaction that just happened
     */
    price: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * A text to specify the recurrency of the billing
     */
    recurrency: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Text that will appear in the button. If it is not provided, no button will be shown
     */
    buttonText: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * The Url of a background image
     */
    imagePath: {
      type: String,
      required: false,
      default: ""
    }
  },
  methods: {
    /**
     * @description
     * Emits an event to handle the button click
     */
    buttonClicked(): void {
      /**
       * Event emitted when the button is clicked.
       * @event cvo-purchase-confirmation-button-click
       */
      this.$emit(Events.CVO_PURCHASE_CONFIRMATION_BUTTON_CLICK);
    }
  }
});
