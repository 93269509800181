

































































import Vue from "vue";
import CardDisplayData from "./model";

enum PERCENTAGES {
  CARD_RADIUS = 3.3,
  CARD_PADDING = 6.6,
  LOGO_FONT_SIZE = 5.3,
  CHIP_WIDTH = 11.6,
  CHIP_MARGINS = 6.6,
  NUMBER_FONT_SIZE = 6,
  NUMBER_MARGINS = 3.3,
  TITLE_FONT_SIZE = 4,
  DESCRIPTION_FONT_SIZE = 5.5
}

export default Vue.extend({
  name: "CvoCardDisplay",

  props: {
    /**
     * Card name, (will be displayed if no logo is provided through slot)
     */
    name: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Expiry month
     */
    expiryMonth: {
      type: [String, Number],
      required: true
    },
    /**
     * Expiry year
     */
    expiryYear: {
      type: [String, Number],
      required: true
    },
    /**
     * Name on the card
     */
    holderName: {
      type: String,
      required: true
    },
    /**
     * Last 4 digits
     */
    lastFour: {
      type: [String, Number],
      required: true
    },
    /**
     *  Text for the card holder on the card display
     */
    cardHolderText: {
      type: String,
      required: false,
      default: "Card Holder"
    },
    /**
     *  Text for the expiration date on the card display
     */
    expirationText: {
      type: String,
      required: false,
      default: "Expires"
    },
    /**
     * Class name to set to the logo container when it's not possible to provide an image through the slot
     */
    classLogo: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Pattern to show the masked numbers before the last four digits
     */
    maskedNumbers: {
      type: String,
      required: false,
      default: "**** **** ****"
    },
    /**
     * Card width. Used when `fluid` is false or ResizeObserver is not available (IE)
     */
    cardWidth: {
      type: String,
      required: false,
      default: "300px"
    },

    /**
     * Whether the component adapts to its parent size
     */
    fluid: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data(): CardDisplayData {
    return {
      sizeObserver: null
    };
  },

  computed: {
    hasResizeObserver() {
      return typeof ResizeObserver !== "undefined";
    }
  },

  watch: {
    cardWidth() {
      if (!this.hasResizeObserver) {
        this.$nextTick(this.setComponentSize);
      }
    }
  },

  mounted(): void {
    this.setComponentSize();
    if (this.fluid && this.hasResizeObserver) {
      this.sizeObserver = new ResizeObserver(this.setComponentSize);
      this.sizeObserver.observe(this.$refs.cardEl as HTMLElement);
    }
  },

  beforeDestroy(): void {
    this.sizeObserver?.disconnect();
  },

  methods: {
    setComponentSize(): void {
      this.setCardStyle();
      this.setLogoStyle();
      this.setChipStyle();
      this.setNumberStyle();
      this.setTitlesStyle();
      this.setDescriptionsStyle();
    },

    getComponentWidth(): number {
      return (this.$el as HTMLElement)?.offsetWidth;
    },

    getSizeByPercentage(percent: number): string {
      return (this.getComponentWidth() * percent) / 100 + "px";
    },

    setCardStyle(): void {
      const el = this.$refs.cardEl as HTMLElement;
      el.style.padding = this.getSizeByPercentage(PERCENTAGES.CARD_PADDING);
      el.style.borderRadius = this.getSizeByPercentage(PERCENTAGES.CARD_RADIUS);
    },

    setLogoStyle(): void {
      const el = this.$refs.logoEl as HTMLElement;
      el.style.fontSize = this.getSizeByPercentage(PERCENTAGES.LOGO_FONT_SIZE);
    },

    setChipStyle(): void {
      const el = this.$refs.chipEl as HTMLElement;
      el.style.width = this.getSizeByPercentage(PERCENTAGES.CHIP_WIDTH);
      el.style.margin = `calc(${this.getSizeByPercentage(
        PERCENTAGES.CHIP_MARGINS
      )} / 2) 0 ${this.getSizeByPercentage(PERCENTAGES.CHIP_MARGINS)}`;
    },

    setNumberStyle(): void {
      const el = this.$refs.numberEl as HTMLElement;
      el.style.fontSize = this.getSizeByPercentage(
        PERCENTAGES.NUMBER_FONT_SIZE
      );
      el.style.margin = `0 0 ${this.getSizeByPercentage(
        PERCENTAGES.NUMBER_MARGINS
      )} 0`;
    },

    setTitlesStyle(): void {
      const holderEl = this.$refs.holderTitleEl as HTMLElement;
      const expiryEl = this.$refs.expiryTitleEl as HTMLElement;
      holderEl.style.fontSize = this.getSizeByPercentage(
        PERCENTAGES.TITLE_FONT_SIZE
      );
      expiryEl.style.fontSize = this.getSizeByPercentage(
        PERCENTAGES.TITLE_FONT_SIZE
      );
    },

    setDescriptionsStyle(): void {
      const holderEl = this.$refs.holderDescriptionEl as HTMLElement;
      const expiryEl = this.$refs.expiryDescriptionEl as HTMLElement;
      holderEl.style.fontSize = this.getSizeByPercentage(
        PERCENTAGES.DESCRIPTION_FONT_SIZE
      );
      expiryEl.style.fontSize = this.getSizeByPercentage(
        PERCENTAGES.DESCRIPTION_FONT_SIZE
      );
    }
  }
});
