





















































import Vue, { PropType } from "vue";
import { PaymentPayload, StepsBarItem } from "@/models";
import CvoStepsBar from "@/components/StepsBar/StepsBar.vue";
import CvoPayment from "@/components/Payment/Payment.vue";
import CvoSimpleCart from "@/components/SimpleCart/SimpleCart.vue";
import CvoMiniCart from "@/components/MiniCart/MiniCart.vue";

import Events from "@/constants/events";

export default Vue.extend({
  name: "CvoPaymentPage",
  components: {
    CvoStepsBar,
    CvoPayment,
    CvoSimpleCart,
    CvoMiniCart
  },
  props: {
    /**
     * List of steps to show in the CvoStepBar
     */
    steps: {
      type: Array as PropType<StepsBarItem[]>,
      required: true
    },

    /**
     * Title for the page
     */
    pageTitle: {
      type: String,
      required: true
    },
    /**
     * Payment form props. Please refer to
     * https://design-system.caravelo.net/cvo-vue-library/storybook/?path=/story/forms-payment
     */
    paymentFormProps: {
      type: Object,
      required: true
    },
    /**
     * Cart props. Please refer to
     * https://design-system.caravelo.net/cvo-vue-library/storybook/?path=/story/checkout-cart-simplecart
     */
    cartProps: {
      type: Object,
      required: true
    }
  },

  methods: {
    /**
     * Emits an event when the selection of country changes
     */
    handleCountryChange(value: string): void {
      /**
       * Emits the value of the select field
       *
       * @event cvo-payment-page-country-change
       * @property {string}
       */
      this.$emit(Events.CVO_PAYMENT_PAGE_COUNTRY_CHANGE, value);
    },

    /**
     * Emits an event when the selection of province / state changes
     */
    handleProvinceStateChange(value: string): void {
      /**
       * Emits the value of the select field `string`
       *
       * @event cvo-payment-page-province-state-change
       * @property {string}
       */
      this.$emit(Events.CVO_PAYMENT_PAGE_PROVINCE_STATE_CHANGE, value);
    },

    /**
     * Emits an event when the selection of city changes
     */
    handleCityChange(value: string): void {
      /**
       * Emits the value of the select field
       *
       * @event cvo-payment-page-city-change
       * @property {string}
       */
      this.$emit(Events.CVO_PAYMENT_PAGE_CITY_CHANGE, value);
    },

    handleSubmit($event: PaymentPayload): void {
      /**
       * Emits the register data when the user submits the form `PaymentPayload`
       *
       * @event cvo-payment-page-submit
       * @property {RegisterPayload} $event
       */
      this.$emit(Events.CVO_PAYMENT_PAGE_SUBMIT, $event);
    },

    handleApplyCoupon($event: string | number): void {
      /**
       * Event emitted to apply the coupon code
       * @event cvo-payment-page-apply-coupon
       */
      this.$emit(Events.CVO_PAYMENT_PAGE_APPLY_COUPON, $event);
    },

    handleEditCart(): void {
      /**
       * Event emitted to edit the cart
       * @event cvo-payment-page-edit-cart
       */
      this.$emit(Events.CVO_PAYMENT_PAGE_EDIT_CART);
    }
  }
});
