export interface FlightDateSelectorInterface {
  initialDate: string;
  visibleDates?: number;
  slideOnSelection?: boolean;
  disabledDates?: string[];
  disabledWeekDays?: string[];
  minDate?: string;
  maxDate?: string;
  dateFormat?: string;
  selectorType?: FlightDateSelectorType;
}

export enum FlightDateSelectorType {
  INBOUND = "inbound",
  OUTBOUND = "outbound"
}
