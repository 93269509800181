























































import Vue, { PropType } from "vue";
import { StepsBarItem } from "@/models";

export default Vue.extend({
  name: "CvoStepsBar",
  props: {
    /**
     * Array of steps
     */
    steps: {
      type: Array as PropType<StepsBarItem[]>,
      required: true
    },
    /**
     * Steps with markers
     */
    withMarkers: {
      type: Boolean,
      required: false,
      default: false
    }
  }
});
