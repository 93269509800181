import Vue from "vue";
import { ValidationProvider } from "vee-validate";

export default Vue.extend({
  components: {
    ValidationProvider
  },

  props: {
    /**
     * Vee Validate - rules
     * @see https://vee-validate.logaretm.com/v3/guide/rules.html
     */
    rules: {
      type: [String, Object],
      default: "",
      required: false
    },

    /**
     * Vee Validate - vid
     * @see https://vee-validate.logaretm.com/v3/advanced/cross-field-validation.html#targeting-other-fields
     */
    vid: {
      type: String,
      default: undefined
    },

    /**
     * Field label
     */
    label: {
      type: String,
      default: "",
      required: false
    },

    /**
     * Field name
     */
    name: {
      type: String,
      default: "",
      required: true
    },

    /**
     * Field is disabled
     */
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },

    /**
     * Used by vee validate
     */
    validationMode: {
      type: [String, Function],
      required: false,
      default: "eager",
      validator: function(value: string | Function): boolean {
        if (typeof value === "string") {
          return (
            ["eager", "aggressive", "lazy", "passive"].indexOf(value) !== -1
          );
        } else if (typeof value === "function") {
          return true;
        } else {
          return false;
        }
      }
    }
  },

  methods: {
    /**
     * Sets the proper classes
     * @param {object} classes Vee Validate classes https://vee-validate.logaretm.com/v3/guide/state.html#css-classes
     * @returns {object} new object with classes
     */
    getClasses(classes: object): object {
      return {
        ...classes,

        // CVO class
        "CvoForm-field": true
      };
    }
  }
});
