










































































import { DefinitionListItem } from "@/models";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "CvoDefinitionList",
  props: {
    items: {
      type: Array as PropType<DefinitionListItem[]>,
      required: true
    }
  },
  data() {
    return {};
  }
});
