



































import Vue, { PropType } from "vue";
import { TilesItem, TileImagePositon } from "@/models/TilesItem";
export default Vue.extend({
  name: "CvoTiles",
  props: {
    title: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * A tile item has the text to display, the
     * position where the image should be placed
     * and the image. The image in this case could
     * be and image url or a CSS class to place an
     * icon. If both the image url and the icon
     * class are provide, the image will be used
     */
    tiles: {
      type: Array as PropType<TilesItem[]>,
      required: true
    }
  },
  methods: {
    imageGoesFirst(tile: TilesItem): boolean {
      return (
        tile.position === TileImagePositon.top ||
        tile.position === TileImagePositon.left
      );
    }
  }
});
