




















import Vue, { PropType } from "vue";
import Autocomplete from "@trevoreyre/autocomplete-vue";
import Events from "@/constants/events";
import AutocompleteData from "./model";
import { AutocompleteItem } from "@/models";

export default Vue.extend({
  name: "CvoAutocomplete",

  components: {
    Autocomplete
  },

  model: {
    prop: "value",
    event: Events.CVO_AUTOCOMPLETE_CHANGE
  },

  props: {
    /**
     * Placeholder for the input element
     */
    placeholder: {
      type: String,
      required: true
    },
    /**
     * Field label
     */
    label: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Time in ms for the component to wait before triggering the search event
     */
    debounceTime: {
      type: Number,
      required: false,
      default: 500
    },
    /**
     * Array of items to show as suggestions
     */
    items: {
      type: Array as () => AutocompleteItem[],
      required: false,
      default: []
    },
    /**
     * Sets initial value
     */
    value: {
      type: Object as PropType<AutocompleteItem>,
      required: false,
      default: null
    },
    /**
     * Minimum characters needed before triggering the search event.
     * To show the suggestions on first focus use `-1` (not recommended with API suggestions)
     */
    minChars: {
      type: Number,
      required: false,
      default: 2
    }
  },

  data(): AutocompleteData {
    return {
      selectedItem: null,
      selectedValue: "",
      resolve: null
    };
  },

  computed: {
    getInputId(): string {
      return this.label.replaceAll(" ", "-");
    }
  },

  watch: {
    /**
     * @description
     * Watch the items property so whenever it
     * changes the promise is resolved and the
     * autocomplete can update the suggestion
     * list
     */
    items(): void {
      if (this.resolve) {
        this.resolve(this.items);
      }
    },

    value(newValue: AutocompleteItem): void {
      this.setInitialValue(newValue);
    }
  },

  mounted(): void {
    if (this.value) {
      this.setInitialValue(this.value);
    }
    this.setInputClasses();
  },

  methods: {
    /**
     * @description
     * Invoked on every interaction with the
     * autocomplete component. Due to the anti-pattern
     * implementation of the 3rd party component, it returns a
     * promise and emits an event to let the parent
     * component handle the API call
     */
    handleSearch(item: string): Promise<AutocompleteItem[]> {
      if (
        item.length <= this.minChars ||
        (item === this.selectedValue && this.minChars > -1)
      ) {
        return Promise.resolve([]);
      }
      return new Promise(resolve => {
        this.resolve = resolve;
        /**
         * Event emitted when typing in the input after the debounce time.
         * It provides the input value `<string>`
         * @event cvo-autocomplete-search
         */
        this.$emit(Events.CVO_AUTOCOMPLETE_SEARCH, item);
      });
    },

    /**
     * @description
     * Emits an event when an option from the
     * autocomplete suggestion is selected
     */
    handleSelect(item: AutocompleteItem | null): void {
      this.selectedItem = item;
      this.selectedValue = this.getResultValue(item);
      /**
       * Event emitted when selecting a suggestion or clearing the input.
       * It provides the selection item `<AutocompleteItem | null>`
       * @event cvo-autocomplete-change
       */
      this.$emit(Events.CVO_AUTOCOMPLETE_CHANGE, item);
    },

    /**
     * @description
     * Function provided to autocomplete that
     * shows the value of the item
     */
    getResultValue(item: AutocompleteItem | null): string {
      let result;
      if (item) {
        result = item.description
          ? `${item.name} (${item.description})`
          : item.name;
      } else {
        result = "";
      }
      return result as string;
    },

    /**
     * @description
     * Handler for the input blur event.
     * It clears the selection if the content input is removed
     * and keeps the selection if at least 1 char is kept.
     */
    handleBlur(event: Event): void {
      const inputEl = event.target as HTMLInputElement;
      if (inputEl.value === "") {
        this.handleSelect(null);
      } else {
        const autocomplete = this.$refs.autocomplete as Vue & {
          value: string | null;
        };
        autocomplete.value = this.selectedValue;
      }
    },
    /**
     * Select items on keydown tab
     * There autocomplete does not provide support for this feature
     * therefore we need this hackish solution.
     * More info:
     * https://github.com/trevoreyre/autocomplete/issues/23
     */
    handleTab() {
      const autocomplete = this.$refs.autocomplete as Vue & {
        selectedIndex: number;
        results: AutocompleteItem[];
      };
      const selectedElement = (autocomplete.$refs
        .resultList as HTMLElement).querySelector(
        "[aria-selected]"
      ) as HTMLElement;

      const index = selectedElement && selectedElement.dataset.resultIndex;
      const item = index && this.items[parseInt(index)];

      item && this.handleSelect(item);
    },
    /**
     * Add cvo classes to elements in order to keep consistency of element styles
     */
    setInputClasses(): void {
      const autocomplete = this.$refs.autocomplete as Vue & {
        $el: HTMLElement;
      };
      const input = autocomplete.$el.querySelector("input");
      input?.classList.add("CvoForm-field");
      input?.classList.add("input");
      this.label && input?.setAttribute("id", this.getInputId);
    },

    setInitialValue(item: AutocompleteItem): void {
      this.handleSelect(item);
      const autocomplete = this.$refs.autocomplete as Vue & {
        value: string | null;
      };
      autocomplete.value = this.selectedValue;
    }
  }
});
