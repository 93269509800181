











































import Vue, { VueConstructor } from "vue";
import FormMixin from "@/mixins/Form/Form.mixin";
import Events from "@/constants/events";
import { LocaleCode } from "@/models";

const ExtendedVue = Vue as VueConstructor<Vue & InstanceType<typeof FormMixin>>;

export default ExtendedVue.extend({
  name: "CvoForm",
  mixins: [FormMixin],
  created() {
    /**
     * Configure the form validator
     * Note: should set the locale for the error messages
     */
    this.formValidator(this.errorsLocale as LocaleCode);
  },
  methods: {
    /**
     * Handle form submit
     */
    onSubmit(): void {
      /**
       * Emits the register data when the user submits the form `RegisterPayload`
       *
       * @event cvo-register-submit
       * @property {RegisterPayload} registePayload
       */
      this.$emit(Events.CVO_FORM_SUBMIT);
    }
  }
});
