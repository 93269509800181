import Vue from "vue";
import Accordion from "@/components/Accordion/Accordion.vue";
import ActionFeedback from "@/components/ActionFeedback/ActionFeedback.vue";
import Addon from "@/components/Addon/Addon.vue";
import AirportServicesTariffs from "@/components/AirportServicesTariffs/AirportServicesTariffs.vue";
import CardAdyen from "@/components/CardAdyen/CardAdyen.vue";
import CardBluefin from "@/components/CardBluefin/CardBluefin.vue";
import CardDisplay from "@/components/CardDisplay/CardDisplay.vue";
import CarouselSlider from "@/components/CarouselSlider/CarouselSlider.vue";
import CartPrivate from "@/components/CartPrivate/CartPrivate.vue";
import Checkbox from "@/components/Checkbox/Checkbox.vue";
import DefinitionList from "@/components/DefinitionList/DefinitionList.vue";
import DirectFlightRow from "@/components/DirectFlightRow/DirectFlightRow.vue";
import EditCardAdyen from "@/components/EditCardAdyen/EditCardAdyen.vue";
import EditSearchFlight from "@/components/EditSearchFlight/EditSearchFlight.vue";
import ExtraBaggage from "@/components/ExtraBaggage/ExtraBaggage.vue";
import Faq from "@/components/Faq/Faq.vue";
import FlightDateSelector from "@/components/FlightDateSelector/FlightDateSelector.vue";
import FlightDetails from "@/components/FlightDetails/FlightDetails.vue";
import FlightSelected from "@/components/FlightSelected/FlightSelected.vue";
import FlightsTable from "@/components/FlightsTable/FlightsTable.vue";
import Form from "@/components/Form/Form.vue";
import Header from "@/components/Header/Header.vue";
import HeroImage from "@/components/HeroImage/HeroImage.vue";
import ImageScroller from "@/components/ImageScroller/ImageScroller.vue";
import ImmersiveCart from "@/components/ImmersiveCart/ImmersiveCart.vue";
import InformationCard from "@/components/InformationCard/InformationCard.vue";
import InputField from "@/components/InputField/InputField.vue";
import Languages from "@/components/Languages/Languages.vue";
import LanguageSelector from "@/components/LanguageSelector/LanguageSelector.vue";
import Loader from "@/components/Loader/Loader.vue";
import Login from "@/components/Login/Login.vue";
import MiniCart from "@/components/MiniCart/MiniCart.vue";
import MultiOptionPlan from "@/components/MultiOptionPlan/MultiOptionPlan.vue";
import MyProfile from "@/components/MyProfile/MyProfile.vue";
import Modal from "@/components/Modal/Modal.vue";
import Navigation from "@/components/Navigation/Navigation.vue";
import NavigationProfile from "@/components/NavigationProfile/NavigationProfile.vue";
import Notification from "@/components/Notification/Notification.vue";
import Overlay from "@/components/Overlay/Overlay.vue";
import PasswordField from "@/components/PasswordField/PasswordField.vue";
import Payment from "@/components/Payment/Payment.vue";
import Plan from "@/components/Plan/Plan.vue";
import PlanComparison from "@/components/PlanComparison/PlanComparison.vue";
import PlanDetails from "@/components/PlanDetails/PlanDetails.vue";
import PriorityBoarding from "@/components/PriorityBoarding/PriorityBoarding.vue";
import PurchaseConfirmation from "@/components/PurchaseConfirmation/PurchaseConfirmation.vue";
import Radio from "@/components/Radio/Radio.vue";
import Register from "@/components/Register/Register.vue";
import SearchFlight from "@/components/SearchFlight/SearchFlight.vue";
import SearchResult from "@/components/SearchResult/SearchResult.vue";
import SelectField from "@/components/SelectField/SelectField.vue";
import SelectFlight from "@/components/SelectFlight/SelectFlight.vue";
import SimpleCart from "@/components/SimpleCart/SimpleCart.vue";
import SimpleFooter from "@/components/SimpleFooter/SimpleFooter.vue";
import StepsBar from "@/components/StepsBar/StepsBar.vue";
import Tiles from "@/components/Tiles/Tiles.vue";
import ToggleButton from "@/components/ToggleButton/ToggleButton.vue";
import Tooltip from "@/components/Tooltip/Tooltip.vue";
import VerticalPlan from "@/components/VerticalPlan/VerticalPlan.vue";

// pages
import BillingInformationPage from "@/pages/BillingInformation/BillingInformation.page.vue";
import ConfigurePlanPage from "@/pages/ConfigurePlan/ConfigurePlan.page.vue";
import ConfirmationPage from "@/pages/Confirmation/Confirmation.page.vue";
import LandingPage from "@/pages/Landing/Landing.page.vue";
import PaymentPage from "@/pages/Payment/Payment.page.vue";
import ProfileOverviewPage from "@/pages/ProfileOverview/ProfileOverview.page.vue";
import RegistrationPage from "@/pages/Registration/Registration.page.vue";

// directives
import Loading from "@/directives/Loading/Loading.directive";
import LoadingV2 from "@/directives/LoadingV2/LoadingV2.directive";

import { VueConstructor } from "vue/types/umd";

type ComponentList = {
  [key: string]: VueConstructor;
};

const components: ComponentList = {
  Accordion,
  ActionFeedback,
  Addon,
  AirportServicesTariffs,
  CardAdyen,
  CardBluefin,
  CardDisplay,
  CarouselSlider,
  CartPrivate,
  Checkbox,
  DefinitionList,
  DirectFlightRow,
  EditCardAdyen,
  EditSearchFlight,
  ExtraBaggage,
  Faq,
  FlightDateSelector,
  FlightDetails,
  FlightSelected,
  FlightsTable,
  Form,
  Header,
  HeroImage,
  ImageScroller,
  ImmersiveCart,
  InformationCard,
  InputField,
  Languages,
  LanguageSelector,
  Loader,
  Login,
  MiniCart,
  MultiOptionPlan,
  MyProfile,
  Modal,
  Navigation,
  NavigationProfile,
  Notification,
  Overlay,
  PasswordField,
  Payment,
  Plan,
  PlanComparison,
  PlanDetails,
  PriorityBoarding,
  PurchaseConfirmation,
  Radio,
  Register,
  SearchFlight,
  SearchResult,
  SelectField,
  SelectFlight,
  SimpleCart,
  SimpleFooter,
  StepsBar,
  Tiles,
  ToggleButton,
  Tooltip,
  VerticalPlan,

  // pages
  BillingInformationPage,
  ConfigurePlanPage,
  ConfirmationPage,
  LandingPage,
  PaymentPage,
  ProfileOverviewPage,
  RegistrationPage
};

Object.keys(components).forEach((name: string) => {
  Vue.component(`Cvo${name}`, components[name]);
});

Vue.directive("loading", Loading);

export default components;

export { Loading, LoadingV2 };
