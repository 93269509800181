








































































































import Vue, { PropType } from "vue";
import Events from "@/constants/events";
import { PlanComparisonItem, PlanComparisonHeader } from "@/models/";

export default Vue.extend({
  name: "CvoPlanComparison",
  props: {
    /**
     * Comparison title
     */
    title: {
      type: String,
      required: false,
      default: null
    },

    /**
     * Comparison description
     */
    description: {
      type: String,
      required: false,
      default: null
    },

    /**
     * Headers for the comparison table
     */
    itemsHeaders: {
      type: Object as PropType<PlanComparisonHeader>,
      required: true
    },

    /**
     * Items to compare
     */
    items: {
      type: Array as PropType<Array<PlanComparisonItem>>,
      required: true
    },

    /**
     * Cancel button text
     */
    cancelText: {
      type: String,
      required: false,
      default: null
    },

    /**
     * Submit button text
     */
    submitText: {
      type: String,
      required: false,
      default: null
    },

    /**
     * Extra information
     */
    information: {
      type: String,
      required: false,
      default: null
    },

    /**
     * T&C and Privacy disclaimer
     */
    termsAndConditions: {
      type: String,
      required: false,
      default: null
    }
  },

  computed: {
    headerKeys(): Array<string> {
      return Object.keys(this.itemsHeaders);
    }
  },

  methods: {
    handleCancel(): void {
      /**
       * Event emitted when cancel button is clicked
       * @event action-click
       */
      this.$emit(Events.CVO_PLAN_COMPARISON_CANCEL);
    },
    handleSubmit(): void {
      /**
       * Event emitted when submit button is clicked
       * @event secondario-action-click
       */
      this.$emit(Events.CVO_PLAN_COMPARISON_SUBMIT);
    }
  }
});
