
























































import Vue, { PropType } from "vue";

import CvoStepsBar from "@/components/StepsBar/StepsBar.vue";
import CvoRegister from "@/components/Register/Register.vue";
import CvoSimpleCart from "@/components/SimpleCart/SimpleCart.vue";
import CvoMiniCart from "@/components/MiniCart/MiniCart.vue";

import { RegisterPayload, StepsBarItem } from "@/models";
import Events from "@/constants/events";

export default Vue.extend({
  name: "CvoRegistrationPage",
  components: {
    CvoStepsBar,
    CvoRegister,
    CvoSimpleCart,
    CvoMiniCart
  },
  props: {
    /**
     * List of steps to show in the CvoStepBar
     */
    steps: {
      type: Array as PropType<StepsBarItem[]>,
      required: true
    },

    /**
     * Title for the page
     */
    pageTitle: {
      type: String,
      required: true
    },

    /**
     * Register form props. Please refer to
     * https://design-system.caravelo.net/cvo-vue-library/storybook/?path=/story/forms-register
     */
    registerFormProps: {
      type: Object,
      required: true
    },

    /**
     * Cart props. Please refer to
     * https://design-system.caravelo.net/cvo-vue-library/storybook/?path=/story/checkout-cart-simplecart
     */
    cartProps: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {
    /**
     * Handles submit form
     */
    handleSubmit($event: RegisterPayload): void {
      /**
       * Emits the register data when the user submits the form `RegisterPayload`
       *
       * @event cvo-registration-submit
       * @property {RegisterPayload} $event
       */
      this.$emit(Events.CVO_REGISTRATION_PAGE_SUBMIT, $event);
    },

    /**
     * Emits an event when the selection of country changes
     */
    handleCountryChange(value: string): void {
      /**
       * Emits the value of the select field
       *
       * @event cvo-registration-page-country-change
       * @property {string}
       */
      this.$emit(Events.CVO_REGISTRATION_PAGE_COUNTRY_CHANGE, value);
    },

    /**
     * Emits an event when the selection of province / state changes
     */
    handleProvinceStateChange(value: string): void {
      /**
       * Emits the value of the select field `string`
       *
       * @event cvo-registration-page-province-state-change
       * @property {string}
       */
      this.$emit(Events.CVO_REGISTRATION_PAGE_PROVINCE_STATE_CHANGE, value);
    },

    /**
     * Emits an event when the selection of city changes
     */
    handleCityChange(value: string): void {
      /**
       * Emits the value of the select field
       *
       * @event cvo-registration-page-city-change
       * @property {string}
       */
      this.$emit(Events.CVO_REGISTRATION_PAGE_CITY_CHANGE, value);
    },

    handleApplyCoupon($event: string | number): void {
      /**
       * Event emitted to apply the coupon code
       * @event cvo-payment-page-apply-coupon
       */
      this.$emit(Events.CVO_REGISTRATION_PAGE_APPLY_COUPON, $event);
    },

    handleEditCart(): void {
      /**
       * Event emitted to edit the cart
       * @event cvo-payment-page-edit-cart
       */
      this.$emit(Events.CVO_REGISTRATION_PAGE_EDIT_CART);
    }
  }
});
