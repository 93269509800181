


















































import Vue, { PropType, VueConstructor } from "vue";
import Events from "@/constants/events";
import FormElementMixin from "@/mixins/FormElement/FormElement.mixin";
import { ToggleButtonItem } from "@/models";
import ToggleButtonData from "./model";

const ExtendedVue = Vue as VueConstructor<
  Vue & InstanceType<typeof FormElementMixin>
>;

export default ExtendedVue.extend({
  name: "CvoToggleButton",

  mixins: [FormElementMixin],

  model: {
    prop: "selectedItem",
    event: Events.CVO_TOGGLE_BUTTON_CHANGE
  },

  props: {
    /**
     * Sets initial value
     */
    selectedItem: {
      type: Object as PropType<ToggleButtonItem>,
      required: false,
      default: null
    },

    /**
     * Items to display
     */
    items: {
      type: Array as PropType<ToggleButtonItem[]>,
      required: true
    }
  },

  data(): ToggleButtonData {
    return {
      currentValue: this.selectedItem
    };
  },

  watch: {
    selectedItem(newValue: ToggleButtonItem): void {
      this.currentValue = newValue;
    },

    currentValue(): void {
      this.handleChange();
    }
  },

  methods: {
    handleChange(): void {
      /**
       * Emits the radio item selected
       *
       * @event cvo-toggle-button-change
       * @property {ToggleButtonItem}
       */
      this.$emit(Events.CVO_TOGGLE_BUTTON_CHANGE, this.currentValue);
    }
  }
});
