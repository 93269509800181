























import Vue, { PropType } from "vue";
import Events from "@/constants/events";
import CvoRadio from "@/components/Radio/Radio.vue";
import { PlanItem } from "@/models/PlanItem";
import { MultiOptionPlanData } from "./model";

export default Vue.extend({
  name: "CvoMultiOptionPlan",
  components: { CvoRadio },
  props: {
    /**
     * Title.
     */
    title: {
      type: String,
      required: true
    },
    /**
     * Short piece of text.
     */
    shortDescription: {
      type: String,
      required: true
    },
    /**
     * A text for the link that provides more info.
     */
    extraInfoLinkText: {
      type: String,
      required: true
    },
    /**
     * The id of the plan selected by default. Optional.
     */
    defaultPlanId: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * An array of PlanItems. Each element of the array will be an option within the plan.
     */
    planOptions: {
      type: Array as PropType<PlanItem[]>,
      required: true
    },
    /**
     * A text to be displayed on the button.
     */
    buttonText: {
      required: true,
      type: String
    }
  },
  data(): MultiOptionPlanData {
    return {
      selectedPlanId:
        this.planOptions.find(option => option.id === this.defaultPlanId) ||
        this.planOptions[0]
    };
  },
  methods: {
    extraInfoLinkClicked() {
      /**
       * Emits the plan selected when the user clicks the button
       *
       * @event cvo-multi-option-plan-extra-info
       */
      this.$emit(Events.CVO_MULTI_OPTION_PLAN_EXTRA_INFO);
    },
    submitButtonClicked() {
      /**
       * Emits the plan selected when the user clicks the button
       *
       * @event cvo-multi-option-plan-submit-button
       * @property {string} planId
       */
      this.$emit(
        Events.CVO_MULTI_OPTION_PLAN_SUBMIT_BUTTON,
        this.selectedPlanId
      );
    }
  }
});
