import Vue, { PropType } from "vue";
import { localize, extend, configure } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import {
  alpha,
  alpha_spaces as alphaSpaces,
  alpha_num as alphaNum,
  required,
  email,
  confirmed,
  numeric,
  min,
  max,
  min_value as minValue,
  max_value as maxValue,
  digits
} from "vee-validate/dist/rules";
import locale from "./locale";
import { CustomValidation, LocaleCode, ValidationItem } from "@/models";
import DateTimeUtils from "@/utils/DateTime.utils";

/**
 * Adds a custom validator to the list of validation rules.
 *
 * @see https://vee-validate.logaretm.com/v3/guide/rules.html#rules
 */
function addValidations(customValidations?: CustomValidation): void {
  extend("alpha", { ...alpha });
  extend("alpha_spaces", { ...alphaSpaces });
  extend("alpha_num", { ...alphaNum });
  extend("email", { ...email });
  extend("confirmed", { ...confirmed });
  extend("numeric", { ...numeric });
  extend("min", { ...min });
  extend("max", { ...max });
  extend("min_value", { ...minValue });
  extend("max_value", { ...maxValue });
  extend("required", { ...required });
  extend("digits", { ...digits });
  extend("alpha_num_space", {
    validate: value => {
      const regex = new RegExp("^[\\w\\s]+$");
      return regex.test(value);
    }
  });
  extend("strong_password", {
    /**
     * Mandatory: 1 Uppercase. 1 lowercase. 1 special char. 1 number. 8 length
     */
    validate: value => {
      const strongRegex = new RegExp(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
      );
      return strongRegex.test(value);
    }
  });
  extend("regular_password", {
    /**
     * Mandatory: 1 Uppercase. 1 lowercase. 1 number. 8 length
     */
    validate: value => {
      const regularRegex = new RegExp(
        "^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)[A-Za-z\\d\\W][^\\n ]{7,}$"
      );
      return regularRegex.test(value);
    }
  });
  extend("not_under_18", {
    /**
     * Validates that a given date was at least 18 years ago.
     */
    validate: value => {
      if (value) {
        return DateTimeUtils.yearsAgo(new Date(value)) >= 18;
      } else {
        return false;
      }
    }
  });

  if (customValidations) {
    // register custom validations dynamically
    for (const field in customValidations) {
      const currentValidation = customValidations[
        field as keyof CustomValidation
      ] as ValidationItem;

      const message =
        typeof currentValidation.message === "function"
          ? currentValidation.message
          : () => currentValidation.message as string;

      let validate;
      if (currentValidation.regex) {
        validate = (value: string) => {
          const customRegex = new RegExp(currentValidation.regex as string);
          return customRegex.test(value);
        };
      }

      if (currentValidation.validate) {
        validate = currentValidation.validate;
      }

      extend(`custom_${field}`, {
        message,
        validate,
        params: [
          {
            name: `custom_${field}`,
            isTarget: currentValidation.isTarget ? true : false
          }
        ]
      });
    }
  }
}

/**
 * Load locale messages
 *
 * @param {string} code - The languague code
 * @see https://vee-validate.logaretm.com/v3/guide/localization.html
 * @returns {Promise<void>}
 */
function loadLocale(code: LocaleCode): void {
  if (locale[code]) {
    localize(code, locale[code]);
  } else {
    localize(code, locale.en);
  }
}

/**
 * Configure classes
 *
 * @see https://vee-validate.logaretm.com/v3/guide/state.html#css-classes
 */
function configureClasses(): void {
  configure({
    classes: {
      valid: "is-success",
      invalid: "help is-danger"
    }
  });
}

/**
 * Create field name
 *
 * @param {string} formName - The form name
 * @param {string} fieldName - The field name
 * @returns {string}
 */
function formFieldName(formName: string, fieldName: string): string {
  return `${formName}_${fieldName}`;
}

export default Vue.extend({
  components: {
    ValidationObserver
  },
  props: {
    /**
     * Form namespace
     */
    formNamespace: {
      required: true,
      type: String
    },
    /**
     * Form errors locale
     */
    errorsLocale: {
      required: false,
      type: String as PropType<LocaleCode>,
      default: "en"
    },
    /**
     * Object for custom validations.
     */
    customValidations: {
      required: false,
      type: Object as PropType<CustomValidation>,
      default: null
    }
  },

  methods: {
    /**
     * Form validator with Vee Validate
     *
     * @param {string} code - The languague code
     *
     * @see https://vee-validate.logaretm.com/v3
     */
    formValidator(code: LocaleCode): void {
      configureClasses();
      addValidations(this.customValidations);
      loadLocale(code);
    },

    /**
     * Get field name
     *
     * @param {string} value - The field name
     */
    getFieldName(value: string): string {
      return formFieldName(this.formNamespace, value);
    }
  }
});
