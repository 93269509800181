












import Vue, { PropType } from "vue";
import { NotificationCategory } from "@/models";
import CvoClose from "@/components/Close/Close.vue";
import Events from "@/constants/events";

const CATEGORY_DEFAULT = "default";

const categories: NotificationCategory[] = [
  "default",
  "info",
  "success",
  "warning",
  "danger"
];

export default Vue.extend({
  name: "CvoNotification",
  components: {
    CvoClose
  },
  model: {
    prop: "show",
    event: Events.CVO_NOTIFICATION_CLOSE
  },
  props: {
    /**
     * Notification category: "default", "info", "success", "warning", "danger";
     */
    category: {
      required: false,
      type: String as PropType<NotificationCategory>,
      default: CATEGORY_DEFAULT,
      validator: function(value: NotificationCategory) {
        return categories.indexOf(value) !== -1;
      }
    },
    /**
     * Show / hide the notification
     */
    show: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      isVisible: this.show
    };
  },
  watch: {
    show(newValue: boolean): void {
      this.isVisible = newValue;
    }
  },
  methods: {
    getStyle(): string {
      const baseStyle = "notification";

      if (this.category !== CATEGORY_DEFAULT) {
        return `${baseStyle} is-${this.category} is-light`;
      }

      return baseStyle;
    },
    handleClose(): void {
      this.isVisible = false;
      /**
       * Model event emitted on notification close
       *
       * @event cvo-notification-close
       */
      this.$emit(Events.CVO_NOTIFICATION_CLOSE, this.isVisible);
    }
  }
});
