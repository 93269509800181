








































































































































































































































































































































































































































import Vue, { PropType, VueConstructor } from "vue";
import UserInfoFormMixin from "@/mixins/UserInfoForm/UserInfoForm.mixin";
import { ValidationObserver } from "vee-validate";
import CvoInputField from "@/components/InputField/InputField.vue";
import CvoRadio from "@/components/Radio/Radio.vue";
import CvoCheckbox from "@/components/Checkbox/Checkbox.vue";
import CvoSelectField from "@/components/SelectField/SelectField.vue";
import CvoTooltip from "@/components/Tooltip/Tooltip.vue";
import DateTimeUtils from "@/utils/DateTime.utils";
import { RadioItem, SelectItem, LocaleCode, PaymentPayload } from "@/models";
import { PaymentData } from "./model";
import Events from "@/constants/events";

type AccountInfoProperty =
  | "firstName"
  | "lastName"
  | "dayOfBirth"
  | "monthOfBirth"
  | "yearOfBirth"
  | "nationality"
  | "email"
  | "address"
  | "postcode"
  | "phone"
  | "phoneCode"
  | "country"
  | "provinceState"
  | "city";

const ExtendedVue = Vue as VueConstructor<
  Vue & InstanceType<typeof UserInfoFormMixin>
>;

export default ExtendedVue.extend({
  name: "CvoPayment",

  components: {
    ValidationObserver,
    CvoInputField,
    CvoCheckbox,
    CvoSelectField,
    CvoRadio,
    CvoTooltip
  },

  mixins: [UserInfoFormMixin],

  props: {
    /**
     * Label for the account information checkbox
     */
    accountInfoLabel: {
      type: String,
      required: true
    },
    /**
     * Object containing account information to populate the form
     */
    accountInfo: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    },
    /**
     * Cardholder first name label
     */
    firstNameLabel: {
      type: String,
      required: true
    },
    /**
     * Cardholder fist name placeholder
     */
    firstNamePlaceholder: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Cardholder last name label
     */
    lastNameLabel: {
      type: String,
      required: true
    },
    /**
     * Cardholder last name placeholder
     */
    lastNamePlaceholder: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Whether or not to show nationality and birthday fields
     */
    includePersonalData: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Birthday label
     */
    birthdayLabel: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Day of birth placeholder
     */
    dayOfBirthPlaceholder: {
      type: String,
      required: false,
      default: "dd"
    },
    /**
     * Month of birth placeholder
     */
    monthOfBirthPlaceholder: {
      type: String,
      required: false,
      default: "mm"
    },
    /**
     * List of months for the birthday select
     */
    monthList: {
      required: false,
      type: Array as PropType<Array<string>>,
      default: () => []
    },
    /**
     * Day of birth placeholder
     */
    yearOfBirthPlaceholder: {
      type: String,
      required: false,
      default: "yyyy"
    },
    /**
     * Format in which the birthday selectors are displayed
     */
    dateSelectFormat: {
      required: false,
      type: String,
      default: "dd-mm-yyyy"
    },
    /**
     * Nationality label
     */
    nationalityLabel: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Nationality placeholder
     */
    nationalityPlaceholder: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Email label
     */
    emailLabel: {
      type: String,
      required: true
    },
    /**
     * Email placeholder
     */
    emailPlaceholder: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Phone label
     */
    phoneLabel: {
      type: String,
      required: true
    },
    /**
     * Phone placeholder
     */
    phonePlaceholder: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Phone placeholder
     */
    phoneCodePlaceholder: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Phone placeholder
     */
    phoneCodeList: {
      type: Array as PropType<SelectItem[]>,
      required: false,
      default: () => []
    },
    /**
     * Address label
     */
    addressLabel: {
      type: String,
      required: true
    },
    /**
     * Address placeholder
     */
    addressPlaceholder: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Postcode label
     */
    postcodeLabel: {
      type: String,
      required: true
    },
    /**
     * Postcode placeholder
     */
    postcodePlaceholder: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Country label
     */
    countryLabel: {
      type: String,
      required: true
    },
    /**
     * Country list for the select control
     */
    countryList: {
      type: Array as PropType<SelectItem[]>,
      required: false,
      default: () => []
    },
    /**
     * Province / State label
     */
    provinceStateLabel: {
      required: true,
      type: String
    },
    /**
     * Province / State list
     */
    provinceStateList: {
      required: false,
      type: Array as PropType<SelectItem[]>,
      default: () => []
    },
    /**
     * Validate province/state. Some countries may not have a province/state list
     */
    hasProvinceState: {
      required: false,
      type: Boolean,
      default: true
    },
    /**
     * City label
     */
    cityLabel: {
      type: String,
      required: true
    },
    /**
     * City list for the select control
     */
    cityList: {
      type: Array as PropType<SelectItem[]>,
      required: false,
      default: () => []
    },
    /**
     * If true, city field will be a select, otherwise it will be an input
     */
    citySelect: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Title for the service start options section
     */
    serviceStartTitle: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Options for service start
     */
    serviceStartOptions: {
      type: Array as PropType<RadioItem[]>,
      required: false,
      default: () => []
    },
    /**
     * Label for the recurrent payment checkbox
     */
    recurrentPaymentsLabel: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Label for terms and conditions
     */
    termsAndConditionsLabel: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Text to be displayed before submit button
     */
    footerText: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Submit button text
     */
    submitText: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Deafult values for the form.
     * It admits an object with PaymentData intefrace
     */
    defaultData: {
      required: false,
      type: Object,
      default: function() {
        return {};
      }
    },
    /**
     * It set the order to ascendent for the birth year dropdown values
     * By default it is false
     */
    birthYearAscOrder: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data(): PaymentData {
    return {
      useAccountInfo: this.defaultData.useAccountInfo || false,
      firstName: this.defaultData.firstName || "",
      lastName: this.defaultData.lastName || "",
      dayOfBirth: this.defaultData.dayOfBirth || "",
      monthOfBirth: this.defaultData.monthOfBirth || "",
      yearOfBirth: this.defaultData.yearOfBirth || "",
      nationality: this.defaultData.nationality || "",
      email: this.defaultData.email || "",
      address: this.defaultData.address || "",
      postcode: this.defaultData.postcode || "",
      phone: this.defaultData.phone || "",
      phoneCode: this.defaultData.phoneCode || "",
      country: this.defaultData.country || "",
      provinceState: this.defaultData.provinceState || "",
      city: this.defaultData.city || "",
      serviceStart: this.defaultData.serviceStart || null,
      acceptRecurrentPayments: false,
      acceptTermsAndConditions: false,
      isProvinceStateLoading: false,
      isCityLoading: false
    };
  },

  created() {
    /**
     * Configure the form validator
     * Note: should set the locale for the error messages
     */
    this.formValidator(this.errorsLocale as LocaleCode);
  },

  mounted() {
    if (this.defaultData.country) {
      this.handleCountryChange(this.defaultData.country);
    }
    if (this.defaultData.provinceState) {
      this.handleProvinceStateChange(this.defaultData.provinceState);
    }
    if (this.defaultData.city) {
      this.handleCityChange(this.defaultData.city);
    }
  },

  computed: {
    yearOfBirthRules(): string {
      const minYear = 1900;
      return `required|numeric|digits:4|min_value:${minYear}|max_value:${DateTimeUtils.getCurrentYear()}`;
    },

    isCardHolderDisabled(): boolean {
      return !this.useAccountInfo;
    },

    isProvinceStateDisabled(): boolean {
      if (!this.hasProvinceState) {
        return !this.hasProvinceState;
      } else {
        return !this.country;
      }
    },

    isCitiesDisabled(): boolean {
      return !this.provinceState;
    },

    days(): Array<SelectItem> {
      let totalDays = 31;

      if (this.monthOfBirth && this.yearOfBirth) {
        totalDays = DateTimeUtils.getDaysInMonth(
          parseInt(this.monthOfBirth),
          parseInt(this.yearOfBirth)
        );
      }

      return Array.from(Array(totalDays)).map((x, i) => {
        const day = i + 1 < 10 ? `0${i + 1}` : `${i + 1}`;
        return { id: day, name: day };
      });
    },

    months(): Array<SelectItem> {
      return Array.from(Array(12)).map((x, i) => {
        const month = i + 1 < 10 ? `0${i + 1}` : `${i + 1}`;
        return {
          id: month,
          name: this.monthList.length ? this.monthList[i] : month
        };
      });
    },

    years(): Array<SelectItem> {
      const AMOUNT_OF_YEARS = 125;
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let i = 0; i <= AMOUNT_OF_YEARS; i++) {
        years.push({
          id: (currentYear - i).toString(),
          name: (currentYear - i).toString()
        });
      }

      if (this.birthYearAscOrder) {
        years.reverse();
      }

      return years;
    },

    getDateSelectClass(): string {
      return this.dateSelectFormat.toLowerCase();
    }
  },

  watch: {
    provinceStateList(): void {
      this.isProvinceStateLoading = false;
    },
    cityList(): void {
      this.isCityLoading = false;
    }
  },

  methods: {
    /**
     * Emits an event when the selection of country changes
     */
    handleCountryChange(value: string, resetDependentValues = true): void {
      if (resetDependentValues) {
        this.provinceState = "";
        this.city = "";
      }
      this.isProvinceStateLoading = true;

      /**
       * Emits the value of the select field
       *
       * @event cvo-payment-country-change
       * @property {string}
       */
      this.$emit(Events.CVO_PAYMENT_COUNTRY_CHANGE, value);
    },

    /**
     * Emits an event when the selection of province / state changes
     */
    handleProvinceStateChange(
      value: string,
      resetDependentValues = true
    ): void {
      if (resetDependentValues) {
        this.city = "";
      }
      this.isCityLoading = true;

      /**
       * Emits the value of the select field `string`
       *
       * @event cvo-payment-province-state-change
       * @property {string}
       */
      this.$emit(Events.CVO_PAYMENT_PROVINCE_STATE_CHANGE, value);
    },

    /**
     * Emits an event when the selection of city changes
     */
    handleCityChange(value: string): void {
      /**
       * Emits the value of the select field
       *
       * @event cvo-payment-city-change
       * @property {string}
       */
      this.$emit(Events.CVO_PAYMENT_CITY_CHANGE, value);
    },

    /**
     * Handles the checkbox change in order to populate the form with the account info
     * @param {boolean} isChecked checkbox value (checked status)
     */
    handleAccountInfoChange(isChecked: boolean): void {
      Object.keys(this.accountInfo).forEach((key: string) => {
        const propertyValue = isChecked ? this.accountInfo[key] : "";
        this[key as AccountInfoProperty] = propertyValue;
      });
    },

    /**
     * Handle form submit
     */
    onSubmit(): void {
      /**
       * Emits the payment data when the user submits the form
       *
       * @event cvo-payment-submit
       * @property {PaymentPayload} paymentPayload
       */
      this.$emit(Events.CVO_PAYMENT_SUBMIT, this.getPaymentData());
    },

    /**
     * Gets the payment data
     * @returns {PaymentPayload}
     */
    getPaymentData(): PaymentPayload {
      const payload = { ...this.$data };
      payload.serviceStart = this.serviceStart?.id || "";
      delete payload.useAccountInfo;

      if (!this.$slots["recurrent-payments"] && !this.recurrentPaymentsLabel) {
        delete payload.acceptRecurrentPayments;
      }

      if (
        !this.$slots["terms-and-conditions"] &&
        !this.termsAndConditionsLabel
      ) {
        delete payload.acceptTermsAndConditions;
      }

      if (!this.serviceStartTitle) {
        delete payload.serviceStart;
      }

      return payload as PaymentPayload;
    },

    /**
     * Checks whether the day select needs to be reset
     */
    checkDay(): void {
      if (this.dayOfBirth && this.monthOfBirth && this.yearOfBirth) {
        const currentSelectedDay = parseInt(this.dayOfBirth);
        const daysInMonth = DateTimeUtils.getDaysInMonth(
          parseInt(this.monthOfBirth),
          parseInt(this.yearOfBirth)
        );
        this.dayOfBirth =
          currentSelectedDay > daysInMonth ? "" : this.dayOfBirth;
      }
    }
  }
});
