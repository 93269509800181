










import Vue from "vue";
import { PropType } from "vue/types/umd";
export default Vue.extend({
  name: "CvoFlightsTable",
  props: {
    /**
     * Array of objects that should match the required properties
     * for the components passed in the flight slot
     */
    flights: {
      type: Array as PropType<Record<string, unknown>[]>,
      required: true
    }
  }
});
