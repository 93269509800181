
























































































import { CarouselSliderItem } from "@/models";
import Vue, { PropType } from "vue";
import EmblaCarousel from "embla-carousel";
import CarouselSliderData from "./model";
import { AlignmentOptionType } from "embla-carousel/embla-carousel-vanilla/alignment";
import Events from "@/constants/events";

export default Vue.extend({
  name: "CvoCarouselSlider",
  props: {
    /**
     * Array of items to show in the carousel `CarouselSliderItem`
     */
    items: {
      type: Array as PropType<CarouselSliderItem[] | null>,
      required: false,
      default: null
    },
    /**
     * Whether the navigation bellow the slider is shown
     */
    showNavigation: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Whether the next and prev controls are shown
     */
    showControls: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Start over when reaching the last slide
     */
    loop: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Slides alignment `start`, `center`, `end`
     */
    align: {
      type: String as PropType<AlignmentOptionType>,
      required: false,
      default: "start",
      validator: (value: AlignmentOptionType) => {
        const accepted: AlignmentOptionType[] = ["start", "center", "end"];
        return accepted.includes(value);
      }
    },
    /**
     * Amount of slides to scroll at once
     */
    slidesToScroll: {
      type: Number,
      required: false,
      default: 1
    },
    /**
     * Amount of slides to show in the viewport (1 to 8)
     * (CSS handled)
     */
    slidesToShow: {
      type: Number,
      required: false,
      default: 1,
      validator: (value: number) => {
        return value >= 0 && value <= 8;
      }
    },
    /**
     * Scroll on dragg
     */
    draggable: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Animation speed
     */
    speed: {
      type: Number,
      required: false,
      default: 10
    },
    /**
     * Set the initial scroll snap to the given number. First snap index starts at 0. Please note that this is not necessarily equal to the number of slides when used together with the slidesToScroll option.
     */
    startIndex: {
      type: Number,
      required: false,
      default: 0
    },
    /**
     * Class to apply to the elements in viewport
     */
    selectedClass: {
      type: String,
      required: false,
      default: "is-selected"
    },
    /**
     * Class to apply to the draggable carousel container
     */
    draggableClass: {
      type: String,
      required: false,
      default: "is-draggable"
    },
    /**
     * Classname that will be applied to the container when dragging
     */
    draggingClass: {
      type: String,
      required: false,
      default: "is-dragging"
    }
  },

  data(): CarouselSliderData {
    return {
      carousel: null,
      navigationLength: 0,
      currentSelected: null,
      isPrevDisabled: false,
      isNextDisabled: false
    };
  },

  mounted() {
    if (this.$slots.default || this.$scopedSlots.default) {
      this.$nextTick(() => {
        this.initCarousel();
      });
    } else {
      this.initCarousel();
    }
  },

  methods: {
    initCarousel(): void {
      const viewPort = this.$refs.carouselViewport as HTMLElement;
      this.carousel = EmblaCarousel(viewPort, {
        skipSnaps: false,
        loop: this.loop,
        slidesToScroll: this.slidesToScroll,
        align: this.align as AlignmentOptionType,
        draggable: this.draggable,
        speed: this.speed,
        startIndex: this.startIndex,
        selectedClass: this.selectedClass,
        draggableClass: this.draggableClass,
        draggingClass: this.draggableClass,
        containScroll: "trimSnaps"
      });
      this.setNavigationLength();
      this.setCarouselListener();
    },

    setNavigationLength(): void {
      this.navigationLength = this.carousel
        ? this.carousel.scrollSnapList().length
        : 0;
    },

    setCarouselListener(): void {
      this.carousel?.on("init", this.selectNavigationButton);
      this.carousel?.on("init", this.setControlsState);
      this.carousel?.on("select", this.selectNavigationButton);
      this.carousel?.on("select", this.setControlsState);
    },

    selectNavigationButton(): void {
      this.currentSelected = this.carousel?.selectedScrollSnap() ?? null;
    },

    setControlsState(): void {
      this.isPrevDisabled = !this.carousel?.canScrollPrev();
      this.isNextDisabled = !this.carousel?.canScrollNext();
    },

    slideClicked(item: CarouselSliderItem): void {
      /**
       * Emits an event when an slide has been clicked
       * The payload is the CarouselSliderItem of the slide
       *
       * @event cvo-carousel-slider-slide-select
       */
      this.$emit(Events.CVO_CAROUSEL_SLIDER_SLIDE_SELECT, item);
    },

    prevButtonClicked(): void {
      /**
       * Emits an event when previous button has been clicked
       *
       * @event cvo-carousel-slider-prev-button-click
       */
      this.$emit(Events.CVO_CAROUSEL_SLIDER_PREV_BUTTON_CLICK);
      this.carousel?.scrollPrev();
    },

    nextButtonClicked(): void {
      /**
       * Emits an event when next button has been clicked
       *
       * @event cvo-carousel-slider-next-button-click
       */
      this.$emit(Events.CVO_CAROUSEL_SLIDER_NEXT_BUTTON_CLICK);
      this.carousel?.scrollNext();
    }
  }
});
