









































































































































































































































































































































































































































import Vue, { PropType, VueConstructor } from "vue";
import UserInfoFormMixin from "@/mixins/UserInfoForm/UserInfoForm.mixin";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Events from "@/constants/events";
import DateTimeUtils from "@/utils/DateTime.utils";
import CvoInputField from "@/components/InputField/InputField.vue";
import CvoPasswordField from "@/components/PasswordField/PasswordField.vue";
import CvoSelectField from "@/components/SelectField/SelectField.vue";
import CvoRadio from "@/components/Radio/Radio.vue";
import CvoCheckbox from "@/components/Checkbox/Checkbox.vue";
import { RegisterData } from "./model";
import {
  RegisterPayload,
  RadioItem,
  LocaleCode,
  SelectItem,
  FormElementValueType
} from "@/models";
import { ScopedSlotChildren } from "vue/types/vnode";

const ExtendedVue = Vue as VueConstructor<
  Vue & InstanceType<typeof UserInfoFormMixin>
>;

export default ExtendedVue.extend({
  name: "CvoRegister",

  components: {
    ValidationObserver,
    ValidationProvider,
    CvoInputField,
    CvoPasswordField,
    CvoSelectField,
    CvoRadio,
    CvoCheckbox
  },

  mixins: [UserInfoFormMixin],

  props: {
    /**
     * Email label
     */
    emailLabel: {
      required: true,
      type: String
    },
    /**
     * Email placeholder
     */
    emailPlaceholder: {
      required: false,
      type: String,
      default: ""
    },
    /**
     * Password label
     */
    passwordLabel: {
      required: true,
      type: String
    },
    /**
     * Password placeholder
     */
    passwordPlaceholder: {
      required: false,
      type: String,
      default: ""
    },
    /**
     * Confirm password label
     */
    confirmPasswordLabel: {
      required: true,
      type: String
    },
    /**
     * Confirm password placeholder
     */
    confirmPasswordPlaceholder: {
      required: false,
      type: String,
      default: ""
    },
    /**
     * Name label
     */
    firstNameLabel: {
      required: true,
      type: String
    },
    /**
     * Name placeholder
     */
    firstNamePlaceholder: {
      required: false,
      type: String,
      default: ""
    },
    /**
     * Middle Name label
     */
    middleNameLabel: {
      required: false,
      type: String,
      default: ""
    },
    /**
     * Name placeholder
     */
    middleNamePlaceholder: {
      required: false,
      type: String,
      default: ""
    },
    /**
     * Lastname label
     */
    lastNameLabel: {
      required: true,
      type: String
    },
    /**
     * Lastname placeholder
     */
    lastNamePlaceholder: {
      required: false,
      type: String,
      default: ""
    },
    /**
     * Suffix label
     */
    nameSuffixLabel: {
      required: false,
      type: String,
      default: ""
    },
    /**
     * Suffix placeholder
     */
    nameSuffixPlaceholder: {
      required: false,
      type: String,
      default: ""
    },
    /**
     * Suffix items to populate the select
     */
    nameSuffixItems: {
      required: false,
      type: Array as PropType<SelectItem[]>,
      default: null
    },
    /**
     * Title of date of birth
     */
    birthdayLabel: {
      required: true,
      type: String
    },
    /**
     * Placeholder of day of birth
     */
    dayOfBirthPlaceholder: {
      required: false,
      type: String,
      default: "dd"
    },
    /**
     * Placeholder of month of birth
     */
    monthOfBirthPlaceholder: {
      required: false,
      type: String,
      default: "mm"
    },
    /**
     * Placeholder of year of birth
     */
    yearOfBirthPlaceholder: {
      required: false,
      type: String,
      default: "yyyy"
    },
    /**
     * List of months for the birthday select
     */
    monthList: {
      required: false,
      type: Array as PropType<Array<string>>,
      default: () => []
    },
    /**
     * Format in which the birthday selectors are displayed
     */
    dateSelectFormat: {
      required: false,
      type: String,
      default: "dd-mm-yyyy"
    },
    /**
     * Gender label
     */
    genderLabel: {
      required: true,
      type: String
    },
    /**
     * Genders items
     */
    genderItems: {
      required: true,
      type: Array as PropType<RadioItem[]>
    },
    /**
     * Nationality label
     */
    nationalityLabel: {
      required: true,
      type: String
    },
    /**
     * Nationality list
     */
    nationalityList: {
      required: true,
      type: Array as PropType<SelectItem[]>
    },
    /**
     * Phone label
     */
    phoneLabel: {
      required: true,
      type: String
    },
    /**
     * Phone code list
     */
    phoneCodeList: {
      required: true,
      type: Array as PropType<SelectItem[]>
    },
    /**
     * Phone placeholder
     */
    phonePlaceholder: {
      required: false,
      type: String,
      default: ""
    },
    /**
     * Address label
     */
    addressLabel: {
      required: true,
      type: String
    },
    /**
     * Address placeholder
     */
    addressPlaceholder: {
      required: false,
      type: String,
      default: ""
    },
    /**
     * Postcode label
     */
    postcodeLabel: {
      required: true,
      type: String
    },
    /**
     * Postcode placeholder
     */
    postcodePlaceholder: {
      required: false,
      type: String,
      default: ""
    },
    /**
     * Country label
     */
    countryLabel: {
      required: true,
      type: String
    },
    /**
     * Country list
     */
    countryList: {
      required: true,
      type: Array as PropType<SelectItem[]>
    },

    /**
     * Province / State list
     */
    provinceStateList: {
      required: false,
      type: Array as PropType<SelectItem[]>,
      default: () => []
    },

    /**
     * Province / State label
     */
    provinceStateLabel: {
      required: true,
      type: String
    },

    /**
     * Validate province/state. Some countries may not have a province/state list
     */
    hasProvinceState: {
      required: false,
      type: Boolean,
      default: true
    },

    /**
     * City list
     */
    cityList: {
      required: false,
      type: Array as PropType<SelectItem[]>,
      default: () => []
    },

    /**
     * City label
     */
    cityLabel: {
      required: true,
      type: String
    },

    /**
     * City placeholder
     */
    cityPlaceholder: {
      required: false,
      type: String,
      default: ""
    },

    /**
     * If true, city field will be a select, otherwise it will be an input
     */
    citySelect: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * Title of terms and conditions
     */
    termsAndConditionsTitle: {
      required: true,
      type: String
    },
    /**
     * Label for terms and conditions
     */
    termsAndConditionsLabel: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Submit text
     */
    submitText: {
      required: true,
      type: String
    },
    /**
     * Deafult values for the form.
     * It admits an object with RegisterData intefrace
     */
    defaultData: {
      required: false,
      type: Object,
      default: function() {
        return {};
      }
    },
    /**
     * If true it will force the user to be older than 18 years old
     */
    allowMinors: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * It set the order to ascendent for the birth year dropdown values
     * By default it is false
     */
    birthYearAscOrder: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data(): RegisterData {
    return {
      email: this.defaultData.email || "",
      password: this.defaultData.password || "",
      confirmPassword: this.defaultData.confirmPassword || "",
      firstName: this.defaultData.firstName || "",
      middleName: this.defaultData.middleName || "",
      lastName: this.defaultData.lastName || "",
      nameSuffix: this.defaultData.nameSuffix || "",
      dayOfBirth: this.defaultData.dayOfBirth || "",
      monthOfBirth: this.defaultData.monthOfBirth || "",
      yearOfBirth: this.defaultData.yearOfBirth || "",
      gender: this.defaultData.gender || null,
      nationality: this.defaultData.nationality || "",
      phoneCode: this.defaultData.phoneCode || "",
      phone: this.defaultData.phone || "",
      address: this.defaultData.address || "",
      postcode: this.defaultData.postcode || "",
      country: this.defaultData.country || "",
      provinceState: this.defaultData.provinceState || "",
      city: this.defaultData.city || "",
      termsAndConditions: false,
      isProvinceStateLoading: false,
      isCityLoading: false,
      birthDateValidationClass: "",
      customFields: {}
    };
  },

  computed: {
    isProvinceStateDisabled(): boolean {
      if (!this.hasProvinceState) {
        return !this.hasProvinceState;
      } else {
        return !this.country;
      }
    },

    isCitiesDisabled(): boolean {
      return !this.provinceState;
    },

    days(): Array<SelectItem> {
      let totalDays = 31;

      if (this.monthOfBirth && this.yearOfBirth) {
        totalDays = DateTimeUtils.getDaysInMonth(
          parseInt(this.monthOfBirth),
          parseInt(this.yearOfBirth)
        );
      }

      return Array.from(Array(totalDays)).map((x, i) => {
        const day = i + 1 < 10 ? `0${i + 1}` : `${i + 1}`;
        return { id: day, name: day };
      });
    },

    months(): Array<SelectItem> {
      return Array.from(Array(12)).map((x, i) => {
        const month = i + 1 < 10 ? `0${i + 1}` : `${i + 1}`;
        return {
          id: month,
          name: this.monthList.length ? this.monthList[i] : month
        };
      });
    },

    years(): Array<SelectItem> {
      const AMOUNT_OF_YEARS = 125;
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let i = 0; i <= AMOUNT_OF_YEARS; i++) {
        years.push({
          id: (currentYear - i).toString(),
          name: (currentYear - i).toString()
        });
      }

      if (this.birthYearAscOrder) {
        years.reverse();
      }

      return years;
    },

    birthDate(): string {
      if (
        !this.allowMinors &&
        this.dayOfBirth &&
        this.monthOfBirth &&
        this.yearOfBirth
      ) {
        return new Date(
          parseInt(this.yearOfBirth),
          parseInt(this.monthOfBirth) - 1,
          parseInt(this.dayOfBirth)
        ).toDateString();
      } else {
        return "";
      }
    },

    getDateSelectClass(): string {
      return this.dateSelectFormat.toLowerCase();
    }
  },

  created() {
    /**
     * Configure the form validator
     * Note: should set the locale for the error messages
     */
    this.formValidator(this.errorsLocale as LocaleCode);
  },

  mounted() {
    this.setCustomFields();

    if (this.defaultData.country) {
      if (this.defaultData.provinceState) {
        if (this.defaultData.city) {
          this.handleCityChange(this.defaultData.city);
        }
        this.handleProvinceStateChange(this.defaultData.provinceState, false);
      }
      this.handleCountryChange(this.defaultData.country, false);
    }
  },

  watch: {
    provinceStateList(): void {
      this.isProvinceStateLoading = false;
    },
    cityList(): void {
      this.isCityLoading = false;
    },
    birthDate(): void {
      if (!this.allowMinors) {
        (this.$refs.birthDateHiddenField as Vue).$el
          .getElementsByTagName("input")[0]
          .dispatchEvent(new Event("change"));
        setTimeout(this.birthdayValidation, 100);
      }
    }
  },

  methods: {
    /**
     * Set a class whenever a the birthdate does not validate
     */
    birthdayValidation(): void {
      if (
        !this.allowMinors &&
        (this.$refs.birthDateHiddenField as Vue).$el
          .getElementsByTagName("input")[0]
          .classList.contains("is-danger")
      ) {
        this.birthDateValidationClass = "CvoRegister-fieldGroup-is-danger";
      } else {
        this.birthDateValidationClass = "";
      }
    },
    /**
     * Get rules for year validation
     */
    getYearRules(): string {
      return `required|numeric|min_value:${this.getMinYear()}|max_value:${this.getMaxYear()}`;
    },

    /**
     * Get min year of birthday
     *
     * @returns number
     */
    getMinYear(): number {
      return 1900;
    },

    /**
     * Get max year of birthday
     *
     * @returns number
     */
    getMaxYear(): number {
      const limit = this.allowMinors ? 0 : 18;
      return DateTimeUtils.getCurrentYear() - limit;
    },

    /**
     * Get register data
     *
     * @returns {RegisterPayload} register payload
     */
    getRegisterData(): RegisterPayload {
      const data = {
        email: this.email,
        password: this.password,
        firstName: this.firstName,
        middleName: this.middleName,
        lastName: this.lastName,
        nameSuffix: this.nameSuffix,
        dayOfBirth: this.dayOfBirth,
        monthOfBirth: this.monthOfBirth,
        yearOfBirth: this.yearOfBirth,
        gender: this.gender?.id || "",
        nationality: this.nationality,
        phoneCode: this.phoneCode,
        phone: this.phone,
        termsAndConditions: this.termsAndConditions,
        address: this.address,
        city: this.city,
        provinceState: this.provinceState,
        country: this.country,
        postcode: this.postcode,
        customFields: this.customFields || null
      } as RegisterPayload;

      return data;
    },

    /**
     * Emits an event when the selection of country changes
     */
    handleCountryChange(value: string, resetDependentValues = true): void {
      if (resetDependentValues) {
        this.provinceState = "";
        this.city = "";
      }
      this.isProvinceStateLoading = true;

      /**
       * Emits the value of the select field `string`
       *
       * @event cvo-register-country-change
       * @property {string}
       */
      this.$emit(Events.CVO_REGISTER_COUNTRY_CHANGE, value);
    },

    /**
     * Emits an event when the selection of province / state changes
     */
    handleProvinceStateChange(
      value: string,
      resetDependentValues = true
    ): void {
      if (resetDependentValues) {
        this.city = "";
      }
      this.isCityLoading = true;
      /**
       * Emits the value of the select field `string`
       *
       * @event cvo-register-province-state-change
       * @property {string}
       */
      this.$emit(Events.CVO_REGISTER_PROVINCE_STATE_CHANGE, value);
    },

    /**
     * Emits an event when the selection of city changes
     */
    handleCityChange(value: string): void {
      /**
       * Emits the value of the select field `string`
       *
       * @event cvo-register-city-change
       * @property {string}
       */
      this.$emit(Events.CVO_REGISTER_CITY_CHANGE, value);
    },

    /**
     * Handle form submit
     */
    onSubmit(): void {
      /**
       * Emits the register data when the user submits the form `RegisterPayload`
       *
       * @event cvo-register-submit
       * @property {RegisterPayload} registePayload
       */
      this.$emit(Events.CVO_REGISTER_SUBMIT, this.getRegisterData());
    },

    /**
     * Checks whether the day select needs to be reset
     */
    checkDay(): void {
      if (this.dayOfBirth && this.monthOfBirth && this.yearOfBirth) {
        const currentSelectedDay = parseInt(this.dayOfBirth);
        const daysInMonth = DateTimeUtils.getDaysInMonth(
          parseInt(this.monthOfBirth),
          parseInt(this.yearOfBirth)
        );
        this.dayOfBirth =
          currentSelectedDay > daysInMonth ? "" : this.dayOfBirth;
      }
    },

    /**
     * Set model for custom fields
     */
    setCustomFields(): void {
      const userFieldsSlot = this.$scopedSlots["user-fields"];
      const personalFieldsSlot = this.$scopedSlots["personal-fields"];
      const otherFields = this.$scopedSlots["other-slots"];

      const slotsCustomFields = [
        userFieldsSlot?.({}),
        personalFieldsSlot?.({}),
        otherFields?.({})
      ];

      this.setCustomModels(slotsCustomFields);
    },

    setCustomModels(slotsArray: ScopedSlotChildren[]): void {
      let context: Vue | undefined;
      for (let i = 0; i < slotsArray.length; i++) {
        if (slotsArray[i]) {
          context = slotsArray[i]?.[0]?.context;
          break;
        }
      }
      if (context) {
        const refs = Object.keys(context.$refs);
        refs.forEach(key => {
          const formElement = context?.$refs[key] as Vue & {
            value: FormElementValueType;
          };
          this.customFields[key] = formElement?.value;
        });
      }
    }
  }
});
