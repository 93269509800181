var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"CvoForm"},[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var handleSubmit = ref.handleSubmit;
    var dirty = ref.dirty;
    var pristine = ref.pristine;
    var valid = ref.valid;
    var invalid = ref.invalid;
    var pending = ref.pending;
    var touched = ref.touched;
    var untouched = ref.untouched;
    var passed = ref.passed;
    var failed = ref.failed;
    var errors = ref.errors;
    var reset = ref.reset;
return [_c('form',{attrs:{"novalidate":"","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_vm._t("default",null,{"dirty":dirty,"pristine":pristine,"valid":valid,"invalid":invalid,"pending":pending,"touched":touched,"untouched":untouched,"passed":passed,"failed":failed,"errors":errors,"reset":reset})],2)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }