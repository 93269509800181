















































import Vue from "vue";
import CvoModal from "@/components/Modal/Modal.vue";
import CvoAutocomplete from "@/components/Autocomplete/Autocomplete.vue";
import Events from "@/constants/events";
import { AutocompleteItem } from "@/models";

export default Vue.extend({
  name: "CvoAirportServicesTariffs",
  components: {
    CvoModal,
    CvoAutocomplete
  },
  props: {
    /**
     * A piece of text between the window title and the dropdown to choose airport
     */
    mainDescriptionText: {
      type: String,
      required: true
    },
    /**
     * A text for the button that displays the modal
     */
    displayButtonText: {
      type: String,
      required: true
    },
    /**
     * A title for the modal
     */
    modalTitle: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Values to be preloaded in the dropdown
     */
    airportsDropdownItems: {
      type: Array as () => AutocompleteItem[],
      required: false,
      default: () => []
    },
    /**
     * The dropdown label
     */
    airportsDropdownLabel: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * The dropdown placeholder
     */
    airportsDropdownPlaceholder: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * A piece of text between the dropdown and the space for the tariff
     */
    tariffDescritionLabel: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * The tariff and its currency
     */
    tariff: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * A piece of text including the last update date
     */
    lastUpdate: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      displayModal: false,
      selectedAirport: ""
    };
  },
  methods: {
    openModal(): void {
      this.displayModal = true;
    },
    handleCloseModal(): void {
      this.displayModal = false;
    },
    handleSearch($event: string): void {
      /**
       * Event emitted when typing in the dropdown.
       * It provides the dropdown value `<string>`
       * @event cvo-airport-services-tariffs-airports
       */
      this.$emit(Events.CVO_AIRPORT_SERVICES_TARIFFS_AIRPORTS, $event);
    },
    handleChange($event: AutocompleteItem | null): void {
      this.selectedAirport = $event ? $event.name : "";
      /**
       * Event emitted when selecting a suggestion or clearing the dropdown.
       * It provides the selection item `<AutocompleteItem | null>`
       * @event cvo-autocomplete-change
       */
      this.$emit(Events.CVO_AIRPORT_SERVICES_TARIFFS_AIRPORT_SELECTED, $event);
    }
  }
});
