import Vue, { PropType } from "vue";
import Events from "@/constants/events";
import { NavigationItem } from "@/models";

export default Vue.extend({
  props: {
    /**
     * Array of links
     */
    links: {
      type: Array as PropType<NavigationItem[]>,
      required: true
    },
    /**
     * Whether the navigation emits an event on clicking
     * on each element or navigates to the link.
     */
    emitEvent: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      isExpanded: false
    };
  },

  methods: {
    /**
     * @description
     * Emits an event to handle te navigation
     */
    handleClick(link: string, $event?: Event): void {
      $event?.preventDefault();
      /**
       * Event emitted when the `emit-event` prop is `true`.
       * Lets the parent handle the navigation.
       * It provides the link `string`.
       * @event cvo-navigation-click
       */
      this.$emit(Events.CVO_NAVIGATION_CLICK, link);
    },

    /**
     * @description
     * Toggle variable that shows/hides the nav on mobile view
     */
    toggleNavigation(): void {
      this.isExpanded = !this.isExpanded;
    }
  }
});
