






































import Vue from "vue";
import Events from "@/constants/events";

export default Vue.extend({
  name: "CvoVerticalPlan",
  props: {
    /**
     * Url of the image to ilustrate the plan. Optional.
     */
    imageUrl: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Title.
     */
    title: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Short piece of text.
     */
    shortDescription: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * A text for the link that provides more info.
     */
    extraInfoLinkText: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * A text with de amount and the currency of the price.
     */
    price: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Currency
     */
    currency: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Whether de currency goes before or after the price
     */
    currencyBeforePrice: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * A text to be displayed on the button.
     */
    buttonText: {
      required: false,
      type: String,
      default: ""
    }
  },
  methods: {
    extraInfoLinkClicked() {
      /**
       * Emits the plan selected when the user clicks the button
       *
       * @event cvo-vertical-plan-extra-info
       */
      this.$emit(Events.CVO_VERTICAL_PLAN_EXTRA_INFO);
    },
    submitButtonClicked() {
      /**
       * Emits the plan selected when the user clicks the button
       *
       * @event cvo-vertical-plan-submit-button
       * @property {string} planId
       */
      this.$emit(Events.CVO_VERTICAL_PLAN_SUBMIT_BUTTON);
    }
  }
});
