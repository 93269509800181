






















import Vue from "vue";
import Events from "@/constants/events";

export default Vue.extend({
  name: "CvoPlan",
  props: {
    planId: {
      required: true,
      type: String
    },
    planType: {
      required: true,
      type: String
    },
    amount: {
      required: true,
      type: String
    },
    paymentRecurrency: {
      required: true,
      type: String
    },
    ctaText: {
      required: true,
      type: String
    }
  },
  methods: {
    handleAction() {
      /**
       * Emits the plan selected when the user clicks the button
       *
       * @event cvo-plan-action
       * @property {string} planId
       */
      this.$emit(Events.CVO_PLAN_ACTION, this.planId);
    }
  }
});
