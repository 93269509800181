









import Events from "@/constants/events";
import Vue from "vue";
export default Vue.extend({
  name: "CvoOverlay",
  props: {
    /**
     * It manages the visibility of the overlay.
     * Not required, true by default
     */
    showOverlay: {
      type: Boolean,
      required: false,
      default: true
    },

    /**
     * A parameter to manage if the overlay hides automatically after one outside click.
     * Not required, false by default.
     */
    autoClose: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isOverlayVisible: this.showOverlay
    };
  },
  watch: {
    showOverlay(newValue: boolean): void {
      this.isOverlayVisible = newValue;
      if (this.isOverlayVisible) {
        this.addClassesToSiblings();
      } else {
        this.removeClassesFromSiblings();
      }
    }
  },
  mounted() {
    if (this.isOverlayVisible) {
      this.addClassesToSiblings();
    }
  },
  methods: {
    /**
     * It emits an event cvo-overlay-outside-click
     * It hides the overlay if autoHide is true
     */
    overlayClicked(): void {
      this.$emit(Events.CVO_OVERLAY_OUTSIDE_CLICK);
      if (this.autoClose) {
        this.isOverlayVisible = false;
        this.removeClassesFromSiblings();
      }
    },

    addClassesToSiblings(): void {
      const overlayEl = this.$refs.CvoOverlay as HTMLElement;
      const siblings = overlayEl?.parentElement?.children || [];
      for (let i = 0; i < siblings.length; i++) {
        if (siblings[i] !== overlayEl) {
          const position = (siblings[i] as HTMLElement).style.position;
          if (!position || position === "static") {
            siblings[i].classList.add("CvoOverlaySiblingPosition");
          }
          siblings[i].classList.add("CvoOverlaySiblingIndex");
        }
      }
    },

    removeClassesFromSiblings(): void {
      const overlayEl = this.$refs.CvoOverlay as HTMLElement;
      const siblings = overlayEl?.parentElement?.children || [];
      for (let i = 0; i < siblings.length; i++) {
        siblings[i].classList.remove("CvoOverlaySiblingPosition");
        siblings[i].classList.remove("CvoOverlaySiblingIndex");
      }
    }
  }
});
