

















import Vue, { PropType } from "vue";
import CvoDefinitionList from "@/components/DefinitionList/DefinitionList.vue";
import { DefinitionListItem } from "@/models";
import Events from "@/constants/events";

export default Vue.extend({
  name: "CvoPlanDetails",
  components: { CvoDefinitionList },
  props: {
    /**
     * A title for the panel
     */
    title: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * A list of DefinitionListItem that represent the details labels and their values
     */
    items: {
      type: Array as PropType<DefinitionListItem[]>,
      required: true
    },
    /**
     * The label that the button will show
     */
    buttonText: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * If true, the component has the class CvoPlanDetails-highlighted
     */
    highlightPanel: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Alternative text for the button if the panel is highlighted
     */
    highlightedButtonText: {
      type: String,
      required: false,
      default: ""
    }
  },
  computed: {
    buttonTextToDisplay(): string {
      return this.highlightPanel ? this.highlightedButtonText : this.buttonText;
    }
  },
  methods: {
    buttonClicked(): void {
      /**
       * Emits an event when the user clicks the button
       *
       * @event cvo-plan-details-button-click
       */
      this.$emit(Events.CVO_PLAN_DETAILS_BUTTON_CLICK, this.highlightPanel);
    }
  }
});
