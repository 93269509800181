export interface TilesItem {
  text: string;
  imageUrl?: string;
  iconClass?: string;
  position: TileImagePositon;
}

export enum TileImagePositon {
  top = "top",
  bottom = "bottom",
  left = "left",
  right = "right"
}
