

















import Vue from "vue";
import CvoInputField from "@/components/InputField/InputField.vue";

export default Vue.extend({
  name: "CvoCardBluefin",
  components: {
    CvoInputField
  },
  props: {
    /**
     * ID for the iframe container
     */
    iframeContainerId: {
      type: String,
      required: true
    },
    /**
     * Name that will will be set to the cvo-input-field
     * which will be used as template by Bluefin.
     * The name will be set as id to cvo-input-field elements.
     * I.e.: `input-name="my-id"`
     * `<label id="my-id-label"></label> <input id="my-id"/>`
     */
    inputName: {
      type: String,
      required: true
    }
  }
});
