/**
 * Date and time utilities
 */
const DateTime = {
  /**
   * Get current year
   *
   * @returns number
   */
  getCurrentYear(): number {
    const currentDate = new Date();
    return currentDate.getFullYear();
  },

  /**
   * Get amount of days in a month by year
   *
   * @returns number
   */
  getDaysInMonth(month: number, year: number): number {
    return new Date(year, month, 0).getDate();
  },

  /**
   * Get the amount of full years passed after a given date
   */
  yearsAgo(date: Date): number {
    const today = new Date(new Date().toDateString());
    let years = today.getFullYear() - date.getFullYear();
    const diffMonths = today.getMonth() - date.getMonth();
    if (
      diffMonths < 0 ||
      (diffMonths === 0 && today.getDate() < date.getDate())
    ) {
      years--;
    }
    return years;
  }
};

export default DateTime;
