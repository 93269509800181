



































import { FlightDetailsJourneyInterface } from "@/models";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "CvoFlightDetails",
  props: {
    journeys: {
      type: Array as PropType<FlightDetailsJourneyInterface[]>,
      required: true
    }
  }
});
