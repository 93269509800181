






































import Vue, { PropType } from "vue";
import { VsaList, VsaItem, VsaHeading, VsaContent } from "vue-simple-accordion";
import { AccordionItem } from "@/models";

export default Vue.extend({
  name: "CvoAccordion",
  components: {
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent
  },
  props: {
    items: {
      type: Array as PropType<AccordionItem[]>,
      required: true
    }
  },
  methods: {
    haveNestedItems(data: AccordionItem) {
      return data.items && Array.isArray(data.items);
    }
  }
});
