


































































































































import Vue, { PropType } from "vue";
import CvoLanguageSelector from "@/components/LanguageSelector/LanguageSelector.vue";
import CvoLogin from "@/components/Login/Login.vue";
import CvoOverlay from "@/components/Overlay/Overlay.vue";
import Events from "@/constants/events";
import {
  LinkItem,
  UserInfo,
  LanguageSelectorInterface,
  LanguageSelectorPayload,
  LoginInterface
} from "@/models";
import { LoginCredentials } from "../Login/models";

export default Vue.extend({
  name: "CvoHeader",
  components: {
    CvoLanguageSelector,
    CvoLogin,
    CvoOverlay
  },
  props: {
    /**
     * If the user is loged in, a gretting to display before the user name
     */
    grettingMessage: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * If the user is loged in, an object with the user info has to be provided
     */
    userInfo: {
      type: Object as PropType<UserInfo>,
      required: false,
      default: null
    },
    /**
     * Text for the login button
     */
    loginText: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Text for the log out button
     */
    logoutText: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Text for the user area button
     */
    userAreaText: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Links to include in the user panel
     */
    links: {
      type: Array as PropType<LinkItem[]>,
      required: false,
      default: []
    },
    /**
     * Whether the navigation emits an event on clicking
     * on each link element or navigates to the link.
     */
    emitEvent: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Properties for the language selector
     */
    languageSelectorProps: {
      type: Object as PropType<LanguageSelectorInterface>,
      required: true
    },
    /**
     * Title for the login panel
     */
    loginPanelTitle: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Properties for the login form
     */
    loginFormProps: {
      type: Object as PropType<LoginInterface>,
      required: false,
      default: null
    },
    /**
     * Whether the user panel should be included.
     */
    includeUserPanel: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Whether the login panel should be included.
     */
    includeLoginPanel: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      isPanelVisible: false
    };
  },
  methods: {
    handleLogoutClick(): void {
      /**
       * Emits an event in order to logout
       *
       * @event cvo-header-logout
       */
      this.$emit(Events.CVO_HEADER_LOGOUT);
    },

    handleUserAreaClick(): void {
      /**
       * Emits an event in order to logout
       *
       * @event cvo-header-logout
       */
      this.$emit(Events.CVO_HEADER_USER_AREA);
    },

    handleLinkClick(link: string, event: Event): void {
      event.preventDefault();
      /**
       * Event emitted when the `emit-event` prop is `true`.
       * Lets the parent handle the navigation.
       * It provides the link `string`.
       * @event cvo-header-link
       */
      this.$emit(Events.CVO_HEADER_LINK_CLICK, link);
    },

    handleLoginButtonClick() {
      /**
       * Emits an event when the login button is clicked.
       * @event cvo-header-login-button-click
       */
      this.$emit(Events.CVO_HEADER_LOGIN_BUTTON_CLICK);
      this.includeLoginPanel && this.toggleUserMenu();
    },

    handleUserButtonClick() {
      /**
       * Emits an event when the login button is clicked.
       * @event cvo-header-user-button-click
       */
      this.$emit(Events.CVO_HEADER_USER_BUTTON_CLICK);
      this.includeUserPanel && this.toggleUserMenu();
    },

    toggleUserMenu(): void {
      this.isPanelVisible = !this.isPanelVisible;
    },

    closeUserMenu(): void {
      this.isPanelVisible = false;
    },

    handleApplyLanguage(options: LanguageSelectorPayload): void {
      /**
       * Emits an event in order to apply the new language
       * and currency selcted `LanguageSelectorPayload`
       *
       * @event cvo-header-language-apply
       */
      this.$emit(Events.CVO_HEADER_LANGUAGE_APPLY, options);
    },

    handleLoginSubmit(loginCredentials: LoginCredentials): void {
      /**
       * Emits the login credentials when the user submits the form. `LoginCredentials`
       *
       * @event cvo-header-login-submit
       * @property {LoginCredentials} loginCredentials
       */
      this.$emit(Events.CVO_HEADER_LOGIN_SUBMIT, loginCredentials);
    },

    handleLoginForgot(link: string): void {
      /**
       * Emits the link to process the forgot user/password. `string`
       *
       * @event cvo-header-forgot-click
       */
      this.$emit(Events.CVO_HEADER_FORGOT_CLICK, link);
    }
  }
});
