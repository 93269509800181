



































import Vue from "vue";
import NavigationMixin from "@/mixins/Navigation/Navigation.mixin";

export default Vue.extend({
  name: "CvoNavigationProfile",
  mixins: [NavigationMixin]
});
