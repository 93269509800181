import Vue, { PropType } from "vue";
import { isBefore, isAfter, isSameDay, getDay, format, parse } from "date-fns";
import { DateFormat } from "@/models";

export default Vue.extend({
  props: {
    /**
     * Array of dates that will be disabled in the UI.
     * Fortmat should be `yyyy-MM-dd`.
     * For instance `['2020-12-24','2020-12-31']`
     */
    disabledDates: {
      type: Array as PropType<Array<string>>,
      required: false,
      default: () => []
    },
    /**
     * An array containing the weekdays to be marked as disabled. From 0 (Sunday) to 6 (Saturday)
     */
    disabledWeekDays: {
      type: Array as PropType<number[]>,
      required: false,
      default: () => []
    },
    /**
     * Minimum allowed date (dates before will be disabled) using ISO format
     */
    minDate: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Maximum allowed date (dates after will be disabled) using ISO format
     */
    maxDate: {
      type: String,
      required: false,
      default: null
    },
    /**
     * The format that will be used to show the date. Use unicode tokens
     */
    dateFormat: {
      type: String,
      required: false,
      default: "dd-MM-yyyy"
    }
  },

  computed: {
    parsedMinDate(): Date | null {
      return this.minDate
        ? parse(this.minDate, DateFormat.ISO, new Date())
        : null;
    },

    parsedMaxDate(): Date | null {
      return this.maxDate
        ? parse(this.maxDate, DateFormat.ISO, new Date())
        : null;
    }
  },

  methods: {
    /**
     * @description
     * Checks if the given date should be enabled or disabled
     */
    isDateDisabled(date: Date): boolean {
      return (
        this.isOnDisabledDatesList(date) ||
        this.isOnDisabledWeekDays(date) ||
        this.isBeforeMinDate(date) ||
        this.isAfterMaxDate(date)
      );
    },

    /**
     * @description
     * Checks if the date is included in the `disabledDates` array
     */
    isOnDisabledDatesList(date: Date): boolean {
      return (
        this.disabledDates.findIndex(disabledDate => {
          const parsed = parse(disabledDate, DateFormat.ISO, new Date());
          return isSameDay(date, parsed);
        }) > -1
      );
    },

    /**
     * @description
     * Checks if the date is included in the `disabledWeekDays` array
     */
    isOnDisabledWeekDays(date: Date): boolean {
      return this.disabledWeekDays.includes(getDay(date));
    },

    /**
     * @description
     * Checks if the date is before the min date
     */
    isBeforeMinDate(date: Date): boolean {
      return this.parsedMinDate ? isBefore(date, this.parsedMinDate) : false;
    },

    /**
     * @description
     * Checks if the date is after the max date
     */
    isAfterMaxDate(date: Date): boolean {
      return this.parsedMaxDate ? isAfter(date, this.parsedMaxDate) : false;
    },

    /**
     * @description
     * Emits an event with a Date in ISO string format as a parameter
     */
    emitDateEvent(eventName: string, date: Date | null): void {
      this.$emit(eventName, date ? format(date, DateFormat.ISO) : null);
    }
  }
});
