import { PropType } from "vue";
import CvoModal from "@/components/Modal/Modal.vue";
import { CartDescriptionItem, PassengerInfo } from "@/models";
import Events from "@/constants/events";
import Vue from "vue";
import CartData from "./model";

export default Vue.extend({
  components: {
    CvoModal
  },

  props: {
    /**
     * Cart title
     */
    title: {
      type: String,
      required: true
    },
    /**
     * Text for the edit cart button
     */
    editButtonText: {
      type: String,
      required: true
    },
    /**
     * Whether it shows the collapse arrow button in mobile breakpoint
     */
    showCollapseButton: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Cart description title
     */
    cartDescriptionTitle: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Items to show on the description panel
     */
    cartDescriptionItems: {
      type: Array as PropType<CartDescriptionItem[]>,
      required: false,
      default: () => []
    },
    /**
     * Text to be included before the cart items
     */
    itemsSectionTitle: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Checkout items including taxes
     */
    items: {
      type: Array,
      required: true
    },
    /**
     * Whether the coupon input is shown or not
     */
    allowCoupon: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Regex for coupon validation
     */
    couponRegex: {
      type: RegExp,
      required: false,
      default: null
    },
    /**
     * Coupon text
     */
    couponText: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Text for the confirm coupon button
     */
    couponActionText: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Message to show when coupon fails
     */
    couponErrorMessage: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Text for the coupon applied or disclaimer
     */
    couponDescription: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Total text
     */
    totalText: {
      type: String,
      required: false,
      default: "Total"
    },
    /**
     * Cart total amount
     */
    total: {
      type: [Number, String],
      required: true
    },
    /**
     * Total currency
     */
    totalCurrency: {
      type: String,
      required: true
    },
    /**
     * Whether de currency goes before or after the price
     */
    currencyBeforePrice: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Modal button text
     */
    modalButtonText: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Whether the pax info is shown or not
     */
    passengerInfo: {
      type: Object as PropType<PassengerInfo>,
      required: false,
      default: null
    },
    /**
     * Text to show as title for passenger info section
     */
    passengerText: {
      type: String,
      required: false,
      default: ""
    }
  },

  data(): CartData {
    return {
      coupon: null,
      displayModal: false,
      isCollapsed: true
    };
  },

  computed: {
    /**
     * Checks if the coupon is valid based on `couponRegex`
     */
    isCouponValid(): boolean {
      return !!(this.coupon && this.couponRegex.test(this.coupon));
    },

    isCouponActionDisabled(): boolean {
      return !this.coupon;
    }
  },

  methods: {
    /**
     * @description
     * Emits an event when a coupon code is submitted
     */
    handleCouponAction(): void {
      let emit = false;
      if (this.couponRegex) {
        emit = this.isCouponValid ? true : false;
        !emit && this.setInputError(true);
      } else {
        emit = true;
      }

      if (emit) {
        /**
         * Event emitted to apply the coupon code
         * @event cvo-cart-apply-coupon
         */
        this.$emit(Events.CVO_CART_APPLY_COUPON, this.coupon);
      }
    },

    /**
     * @description
     * Emits an event when the edit button is clicked
     */
    handleEditCart(): void {
      /**
       * Event emitted to edit the cart
       * @event cvo-cart-edit-cart
       */
      this.$emit(Events.CVO_CART_EDIT_CART);
    },

    /**
     * @description
     * Emits an event when the edit passenger button is clicked
     */
    handleEditPassenger(): void {
      /**
       * Event emitted to edit the passenger
       * @event cvo-cart-edit-passenger
       */
      this.$emit(Events.CVO_CART_EDIT_PASSENGER);
    },

    /**
     * @description
     * Opens the extra info modal
     */
    openModal(): void {
      this.displayModal = true;
    },

    /**
     * @description
     * Closes the modal
     */
    handleModalClose(): void {
      this.displayModal = false;
    },

    /**
     * @description
     * Sets the error class when input is invalid
     */
    setInputError(toggle: boolean): void {
      (this.$refs.couponInput as HTMLElement).classList.toggle(
        "is-invalid",
        toggle
      );
    },

    /**
     * @description
     * Resets input error status
     */
    handleCouponInput() {
      this.setInputError(false);
    },

    /**
     * Shows / hide cart sections
     */
    toggleCartCollapse(): void {
      this.isCollapsed = !this.isCollapsed;
    }
  }
});
