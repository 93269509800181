import Vue, { PropType } from "vue";
import { LinkItem } from "@/models";
import Events from "@/constants/events";

export default Vue.extend({
  props: {
    /**
     * Copyright text
     */
    copyrightText: {
      type: String,
      required: true
    },

    /**
     * Link items
     */
    items: {
      type: Array as PropType<LinkItem[]>,
      required: false,
      default: () => []
    }
  },
  methods: {
    onItemClick(link: string, $event: MouseEvent): void {
      $event.preventDefault();

      /**
       * Emits an event in order to redirect to the provided url
       *
       * @event cvo-footer-link-click
       */
      this.$emit(Events.CVO_FOOTER_LINK_CLICK, link);
    }
  }
});
